import { trigger, transition, style, animate } from '@angular/animations';
import { Component, HostBinding, OnInit } from '@angular/core';
import { CueContentType, CueElement, CueElementAware } from '@cue-core';
import { TestimonialItemModel, TestimonialModuleModel } from '@core/content-models';
import { Breakpoint, BreakpointService } from '@services/breakpoint.service';

@CueContentType({ types: ['testimonial_module', 'filter_testimonial_module'] })
@Component({
  selector: 'sdx-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('1s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1, position: 'absolute' }), animate('1s ease-in', style({ opacity: 0 }))]),
    ]),
  ],
})
export class TestimonialComponent implements OnInit, CueElementAware {

  public cueElement: TestimonialModuleModel;

  public current: TestimonialItemModel;

  @HostBinding('class')
  get hostClasses(): string[] {
    return ['spacing-top-' + this.cueElement.spacing_top, 'spacing-bottom-' + this.cueElement.spacing_bottom];
  }

  public inView = false;

  public currentIndex = 0;

  public isMobile = false;

  constructor(private breakpointService: BreakpointService) { }

  ngOnInit(): void {
    this.breakpointService.active.subscribe((breakpoints) => (this.isMobile = breakpoints.includes(Breakpoint.XS_MAX)));
    this.current = this.cueElement.elements[0];
  }

  public prev() {
    if (this.currentIndex === 0) {
      this.currentIndex = this.cueElement.elements.length - 1;
    } else {
      this.currentIndex -= 1;
    }
  }

  public next() {
    if (this.currentIndex >= this.cueElement.elements.length - 1) {
      this.currentIndex = 0;
    } else {
      this.currentIndex += 1;
    }
  }

  public setIndex(i: number) {
    this.currentIndex = i;
  }

  public trackByFnc = (i: number, n: CueElement) => n.id;

}
