import { ChangeDetectionStrategy, Component, HostBinding, OnInit } from '@angular/core';
import { CueContentType, CueElement, CueElementAware } from '@cue-core';
import { BulletinListModel } from '@core/content-models';
import { AnimationStateService } from '@services/animation-state.service';
import { staggerIn } from '@core/animations';
import { BaseComponent } from '@core/base.component';

@CueContentType({ types: ['bulletin_list'] })
@Component({
  selector: 'sdx-bulletin',
  templateUrl: './bulletin.component.html',
  styleUrls: ['./bulletin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [staggerIn],
})
export class BulletinComponent extends BaseComponent implements OnInit, CueElementAware {

  public cueElement: BulletinListModel;

  @HostBinding('class')
  get hostClasses(): string[] {

    const classList = [
      'spacing-top-' + this.cueElement.spacing_top,
      'spacing-bottom-' + this.cueElement.spacing_bottom,
    ];

    if (this.cueElement.level === 0) {
      classList.push('p-horizontal');
    }

    return classList;
  }

  public inView = false;

  constructor(private animationStateService: AnimationStateService) {
    super();
  }

  ngOnInit() {
    this.animationStateService.disabled
      .pipe(this.takeUntilDestroyed())
      .subscribe((disabled) => this.disableAnimations = disabled);
  }

  public trackByFnc = (i: number, n: CueElement) => n.id;

}
