import { Component, HostBinding, OnInit } from '@angular/core';
import { CueContentType, CueElement, CueElementAware } from '@cue-core';
import { SolutionCardModuleModel } from '@core/content-models';
import { AnimationStateService } from '@services/animation-state.service';
import { groupStagger, staggerIn } from '@core/animations';
import { BaseComponent } from '@core/base.component';

@CueContentType({ types: ['solution_card_module'] })
@Component({
  selector: 'sdx-solution-cards',
  templateUrl: './solution-cards.component.html',
  styleUrls: ['./solution-cards.component.scss'],
  animations: [groupStagger, staggerIn],
})
export class SolutionCardsComponent extends BaseComponent implements OnInit, CueElementAware {
  cueElement: SolutionCardModuleModel;

  @HostBinding('class')
  get hostClasses(): string[] {
    return ['spacing-top-' + this.cueElement.spacing_top, 'spacing-bottom-' + this.cueElement.spacing_bottom];
  }

  public inView = false;

  constructor(private animationStateService: AnimationStateService) {
    super();
  }

  ngOnInit() {
    this.animationStateService.disabled
      .pipe(this.takeUntilDestroyed())
      .subscribe((disabled) => this.disableAnimations = disabled);
  }

  public trackByFnc = (i: number, n: CueElement) => n.id;
}
