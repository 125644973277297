import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '@services/language.service';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'translate',
  pure: false,
})
export class TranslatePipe implements PipeTransform {
  constructor(private langaugeService: LanguageService) {}

  transform(value: string): any {
    const key = typeof value === 'string' ? value.toLowerCase() : value;
    return this.langaugeService.observable.pipe(map(() => this.langaugeService.get(key)));
  }
}
