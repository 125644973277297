<div class="container-01">

  <div class="p-default">

    <span class="panel-close" (click)="back()">Back</span>

    <sdx-search-form (isLoading)="isLoading = $event" (results)="results = $event" (queryChange)="updateQuery($event)" [query]="query"></sdx-search-form>

    <sdx-search-results *ngIf="!isLoading && results" [results]="results" [query]="query"></sdx-search-results>

    <sdx-loader *ngIf="isLoading" [layout]="'flex'"></sdx-loader>

  </div>

</div>
