import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum OpacityLayer {
  Navigation
}
@Injectable({
  providedIn: 'root'
})
export class OpacityLayerService {

  public isActive = new BehaviorSubject<OpacityLayer>(null);

  public enable(layer: OpacityLayer) {
    this.isActive.next(layer);
  }

  public disable() {
    this.isActive.next(null);
  }
}
