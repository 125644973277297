<figure class="campaign-background">
  <picture class="campaign-picture" [ngClass]="{'is-ready': cueElement.backgroundImage.isLoaded}">
    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" class="object-fit-cover campaign-img" [alt]="cueElement.backgroundImage.alt_text" [sdxLazyload]="cueElement.backgroundImage.imageSources" (lazyloaded)="cueElement.backgroundImage.isLoaded = true;" />
  </picture>
</figure>
<div class="campaign-container p-horizontal" >

<div class="campaign-back p-top">
  <a routerLink href="/" title="StiboDX" class="d-none d-sm-flex"><img class="campaign-logo" width="130" height="28" alt="StiboDX Logo" src="../../../assets/stibodx_logo_white.png" /></a>
  <sdx-cta-back [text]="'To Frontpage'" [href]="'/'"></sdx-cta-back>
</div>

<div class="campaign-content">
  <sdx-cue-content-portal *ngFor="let e of cueElement.elements; trackBy: trackByFnc" [content]="e"></sdx-cue-content-portal>
</div>

<div *ngIf="cueElement.htmlSnippet" class="campaign-form">
  <sdx-cue-content-portal [content]="cueElement.htmlSnippet"></sdx-cue-content-portal>
</div>
</div>
