<div class="container-03 mb-3" *ngIf="cueElement" (sdxInView)="inView = true">
  <div *ngFor="let item of cueElement.elements; trackBy: trackByFnc index as i;">
    <div class="testimonial" [ngClass]="cueElement.theme" *ngIf="i === currentIndex">
      <picture *ngIf="item.image" class="testimonial-picture lazyload-picture" [ngClass]="{'is-ready': item.image.isLoaded}">
        <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" class="testimonial-img object-fit-cover lazyload-img" [sdxLazyload]="item.image.imageSources" [lazyloadIf]="inView" (lazyloaded)="item.image.isLoaded = true" [alt]="item.image.alt_text" />
      </picture>

      <div class="testimonial-content">
        <div class="citation rte">
          <p class="citation-quote">“{{item.quote}}”</p>
          <div class="citation-source">
            <picture *ngIf="item.authorImage" class="citation-logo lazyload-picture" [ngClass]="{'is-ready': item.authorImage.isLoaded}">
              <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" class="lazyload-img" [sdxLazyload]="item.authorImage.imageSources" [lazyloadIf]="inView" (lazyloaded)="item.authorImage.isLoaded = true" [alt]="item.authorImage.alt_text" />
            </picture>
            <div class="citation-author">
              <p class="citation-author-name">{{item.authorName}}</p>
              <p>{{item.authorTitle}}</p>
            </div>
          </div>
        </div>
        <div class="testimonial-footer">
          <div>
            <sdx-cta-tertiary *ngIf="item.link" [theme]="cueElement.ctaTheme" [text]="item.link.text" [href]="item.link.text"></sdx-cta-tertiary>
          </div>
          <div class="testimonial-dots" *ngIf="cueElement.elements.length > 1 && isMobile">
            <sdx-carousel-indicator [current]="currentIndex" [amount]="cueElement.elements.length" (changeCurrent)="setIndex($event)"></sdx-carousel-indicator>
          </div>
          <div class="testimonial-numbers" *ngIf="cueElement.elements.length > 1 && !isMobile">
            <sdx-slider-indicator [current]="currentIndex" [amount]="cueElement.elements.length" (previous)="prev()" (next)="next()"></sdx-slider-indicator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
