import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum AnimationState {
  FirstView = 'firstView',
  FirstLevel = 'firstLevel',
  SeondLevel = 'secondLevel',
  None = 'none'
}

@Injectable({
  providedIn: 'root',
})
export class AnimationStateService {

  public disabled = new BehaviorSubject<boolean>(true);

  constructor() { }

  public disable(state: boolean) {
    this.disabled.next(state);
  }
}
