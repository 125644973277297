<div class="toolbar-container">
  <div class="toolbar-inner">
    <div class="toolbar-content">
      <a *ngIf="link" class="toolbar-link" [attr.href]="link.href" [attr.title]="link.label">{{link.text}}</a>
    </div>

    <div *ngIf="isDesktop" class="toolbar-util">

      <nav class="toolbar-nav">
        <a *ngFor="let n of items; trackBy: trackByIndex" class="toolbar-link" [attr.href]="n.href" [attr.title]="n.label">{{n.text}}</a>
      </nav>

      <div class="toolbar-search" [routerLink]="['/search']"></div>

    </div>
  </div>
</div>
