import { AfterViewInit, ElementRef, HostListener, Input, OnChanges, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { OpacityLayer, OpacityLayerService } from '../opacity-layer/opacity-layer.service';
import { Breakpoint, BreakpointService } from '@services/breakpoint.service';
import { ICueNavChild, ICueNavigation, ICueNavItem } from '@cue-core';
import { NavigationStart, Router } from '@angular/router';
@Component({
  selector: 'sdx-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnChanges, AfterViewInit {

  @Input()
  public data: ICueNavigation;

  public isActive = false;
  public isDesktop = false;

  public delayMouseLeave = false;

  public toolbarItems: ICueNavItem[];

  public primaryItems: ICueNavChild[];

  public activeItem: ICueNavChild = null;

  public activeItems: ICueNavChild[] = null;

  public cta: ICueNavChild = null;

  @ViewChild('mainNav', { static: false })
  public mainNav: ElementRef;

  public navOffset = 0;

  constructor(private opacityLayerService: OpacityLayerService, private breakpointService: BreakpointService, private router: Router) {

    this.opacityLayerService.isActive.subscribe(n => this.isActive = n === OpacityLayer.Navigation);

    this.breakpointService.active.subscribe((breakpoints) => {
      this.isDesktop = breakpoints.includes(Breakpoint.LG_MIN);
      if (this.isDesktop && this.isActive) {
        this.toggleNav();
      }
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.activeItem = null;
        this.activeItems = null;
      }
    });
  }

  ngOnChanges() {
    this.toolbarItems = this.data.top_header.filter((n, i) => !!n.item).map(n => n.item);

    // Ensures that the header data is not mutated, first on the sever and then statetranfered
    const header = [... this.data.header];

    this.cta = header.pop();
    this.primaryItems = header;
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.onResize(), 0);
  }

  public toggleNav() {
    if (this.isActive) {
      this.opacityLayerService.disable();
    } else {
      this.opacityLayerService.enable(OpacityLayer.Navigation);
    }
  }

  public onMouseLeave() {
    if (this.delayMouseLeave) {
      return;
    }
    this.activeItem = null;
    this.activeItems = null;
  }

  public onMouseHover(item: ICueNavChild) {
    const scroll = window.scrollY;
    if (item.children && item.children.length > 0) {
      this.activeItem = item;
      this.activeItems = item.children;
    } else {
      this.activeItem = null;
      this.activeItems = null;
    }

    this.delayMouseLeave = true;
    setTimeout(() => {
      window.scrollTo({ top: scroll });
      this.delayMouseLeave = false;
    }, 0);
  }

  @HostListener('window:resize')
  private onResize() {
    this.navOffset = (this.mainNav.nativeElement as HTMLElement).offsetLeft;
  }

  public isUrlActive(url: string) {
    return (this.router.url.includes(url));
  }

  public trackByIndex = (i: number, n: ICueNavChild) => i;

}
