import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CtaPrimaryComponent } from './cta-primary/cta-primary.component';
import { CtaSecondaryComponent } from './cta-secondary/cta-secondary.component';
import { CtaTertiaryComponent } from './cta-tertiary/cta-tertiary.component';
import { CtaChipComponent } from './cta-chip/cta-chip.component';
import { CarouselIndicatorComponent } from './carousel-indicator/carousel-indicator.component';
import { SliderIndicatorComponent } from './slider-indicator/slider-indicator.component';
import { CtaBackComponent } from './cta-back/cta-back.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    CtaPrimaryComponent,
    CtaSecondaryComponent,
    CtaTertiaryComponent,
    CtaChipComponent,
    CarouselIndicatorComponent,
    SliderIndicatorComponent,
    CtaBackComponent,
  ],
  imports: [CommonModule, RouterModule, SharedModule],
  exports: [
    CtaPrimaryComponent,
    CtaSecondaryComponent,
    CtaTertiaryComponent,
    CtaChipComponent,
    CarouselIndicatorComponent,
    SliderIndicatorComponent,
    CtaBackComponent,
  ],
})
export class CTAsModule {}
