import { Directive, OnChanges, Output, HostBinding, SimpleChanges, HostListener, EventEmitter, Input } from '@angular/core';
import { Breakpoint, BreakpointService } from '@services/breakpoint.service';
import { ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export interface IImageSource {
  bp: Breakpoint;
  url: string;
}

@Directive({
  selector: 'img[sdxLazyload]',
})
export class LazyloadDirective implements OnChanges {

  @Input()
  public sdxLazyload: IImageSource[] = [];

  @Input()
  public lazyloadIf = true;

  @Output()
  public lazyloaded = new EventEmitter<string>();

  @HostBinding('attr.src')
  public src: string;

  @HostBinding('class.lazyloading')
  isLoading = false;

  @HostBinding('class.lazyloaded')
  isLoaded = false;

  private breakpoints: Breakpoint[] = [];
  private breakpointMap = new Map<Breakpoint, string>();
  private breakpoint: Breakpoint;

  private changes = new ReplaySubject<string>(1);

  constructor(private breakpointService: BreakpointService) {
    this.breakpointService.active.subscribe((breakpoints) => {
      this.breakpoints = breakpoints;
      this.changes.next();
    });

    this.changes.pipe(debounceTime(16)).subscribe(() => this.onChange());
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.sdxLazyload && changes.sdxLazyload.currentValue !== changes.sdxLazyload.previousValue) {
      this.breakpointMap.clear();
      this.sdxLazyload.forEach((n) => this.breakpointMap.set(n.bp, n.url));
    }

    this.changes.next();
  }

  public onChange() {
    if (!this.breakpoints.length || !this.sdxLazyload.length || !this.lazyloadIf) {
      return;
    }

    const breakpoint = [... this.breakpoints].reverse().find((n) => this.breakpointMap.has(n));
    if (this.breakpoint === breakpoint) {
      return;
    }

    const url = this.breakpointMap.get(breakpoint);
    if (this.src === url) {
      return;
    }

    this.breakpoint = breakpoint;
    this.isLoading = true;
    this.src = url;
  }

  @HostListener('load')
  public load() {
    this.isLoading = false;
    this.isLoaded = true;
    this.lazyloaded.next(this.src);
  }

  @HostListener('error', ['$event'])
  public error($event: ErrorEvent) {
    this.isLoading = false;
    console.error('Error loading image', this.src, $event);
  }
}
