import { HttpClientModule } from '@angular/common/http';
import { ANALYZE_FOR_ENTRY_COMPONENTS, ModuleWithProviders, NgModule, Provider, Type } from '@angular/core';
import { provideRoutes, ROUTER_CONFIGURATION, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { CanActivateCueRoute, DetectCueRouteResolver, CueDataResolver } from './cue-router-content-resolver';
import { CUE_ROUTER_CONFIGURATION, CueRouteConfiguration, CueRouter } from './cue-router';
import { CueRouterOutletComponent } from './cue-router-outlet';
import { CueRouterReuseStrategy } from './cue-router-reuse-strategy';
import { cueUrlMatcher } from './cue-url-matcher';
import { CueCoreModule } from '../cue-core';

export const CUE_ROUTER_PROVIDERS: Provider[] = [DetectCueRouteResolver, CueDataResolver, CanActivateCueRoute, CueRouter];

export function provideCueRoutes(layout: Type<any>, childRoutes: Routes, routesToOverride: Routes) {
  return provideRoutes([
    ...(routesToOverride ? routesToOverride : []),
    {
      path: '',
      component: layout,
      children: [
        {
          matcher: cueUrlMatcher,
          component: CueRouterOutletComponent,
          children: childRoutes ? childRoutes : [],
          resolve: {
            isCueRoute: DetectCueRouteResolver,
            cue: CueDataResolver,
          },
          canActivate: [CanActivateCueRoute],
        },
      ],
    },
  ]);
}

@NgModule({
  imports: [RouterModule.forRoot([], { relativeLinkResolution: 'legacy', initialNavigation: 'enabled' }), HttpClientModule, CueCoreModule],
  exports: [RouterModule],
  declarations: [CueRouterOutletComponent],
})
export class CueRouterModule {
  // Note: We are injecting the CueRouter so it gets created eagerly...
  constructor(private cueRouter: CueRouter) {}

  static forRoot(layout: Type<any>, configuration: CueRouteConfiguration = {}): ModuleWithProviders<CueRouterModule> {
    return {
      ngModule: CueRouterModule,
      providers: [
        CUE_ROUTER_PROVIDERS,
        provideCueRoutes(layout, configuration.childRoutes, configuration.routes),
        {
          provide: ROUTER_CONFIGURATION,
          useValue: configuration.options,
        },
        {
          provide: CUE_ROUTER_CONFIGURATION,
          useValue: configuration,
        },
        { provide: RouteReuseStrategy, useClass: CueRouterReuseStrategy },
      ],
    };
  }

  // static forChild(routes: Routes): ModuleWithProviders<CueRouterModule> {
  //   return {
  //     ngModule: CueRouterModule,
  //     providers: [provideRoutes(routes)],
  //   };
  // }
}
