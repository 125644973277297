<section class="container-03 mb-5" (sdxInView)="inView = true" [@staggerIn]="inView">
  <div [ngClass]="cueElement.theme">
    <div class="lead-generator-content rte p-top p-bottom">
      <h3 class="stagger-in"><b>{{cueElement.headline}}</b></h3>
      <p class="stagger-in">{{cueElement.paragraph}}</p>

      <sdx-cta-primary class="d-flex justify-content-center stagger-in" *ngIf="!!cueElement.link && cueElement.link.cta_type === 'primary'" [href]="cueElement.link.link" [text]="cueElement.link.text" [label]="cueElement.link.text" [theme]="cueElement.ctaTheme"></sdx-cta-primary>
      <sdx-cta-secondary class="d-flex justify-content-center stagger-in" *ngIf="!!cueElement.link && cueElement.link.cta_type === 'secondary'" [href]="cueElement.link.link" [text]="cueElement.link.text" [label]="cueElement.link.text" [theme]="cueElement.ctaTheme"></sdx-cta-secondary>
      <sdx-cta-tertiary class="d-flex justify-content-center stagger-in" *ngIf="!!cueElement.link && cueElement.link.cta_type === 'tertiary'" [href]="cueElement.link.link" [text]="cueElement.link.text" [label]="cueElement.link.text" [theme]="cueElement.ctaTheme"></sdx-cta-tertiary>
    </div>
  </div>
</section>
