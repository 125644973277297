import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';
import { skip } from 'rxjs/operators';

export interface IViewState {
  url: string,
  scrollY: number;
  data: Map<string, any>;
}

@Injectable({
  providedIn: 'root'
})
export class ViewStateService {

  public stack: IViewState[] = [];
  public currentState: IViewState = { url: '', scrollY: 0, data: new Map<string, any>() };
  public poppedState: IViewState = null;

  constructor(
    private location: Location,
    private router: Router) {
  }

  public listen() {
    this.location.subscribe((ev: PopStateEvent) => {
      if (this.stack.length > 0) {
        this.poppedState = this.stack.pop();
      }
    });

    this.router.events.pipe(skip(2)).subscribe((ev: any) => {

      if (ev instanceof NavigationStart) {
        this.currentState.scrollY = window.scrollY;
        this.stack.push(this.currentState);
        this.currentState = { url: ev.url, scrollY: 0, data: new Map<string, any>() };

        if (this.poppedState) {
          this.currentState = this.poppedState;
          this.poppedState = null;
        }
      }

      if (ev instanceof NavigationEnd) {

        this.currentState.url = ev.url;
        if (this.currentState.scrollY > 0) {
          setTimeout(() => {
            window.scrollTo({ top: this.currentState.scrollY, left: 0, behavior: 'smooth' });
          }, 1500);
        }
      }
    });
  }

  public setData(key: string, data: any) {
    this.currentState.data.set(key, data);
  }

  public getData(key: string) {
    return this.currentState.data.get(key);
  }

}
