<div class="container-02" (sdxInView)="inView = true">
  <div class="row v-gutters">

    <div class="col-12 col-md-6" *ngFor="let card of cueElement.elements; trackBy: trackByFnc">
      <figure class="highlight-spots-container">
        <div class="highlight-spots-media">
          <picture class="highlight-spots-picture lazyload-picture ratio-1-1" *ngIf="card.imageSources" [ngClass]="{'is-ready': card.image.isLoaded}">
            <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" class="lazyload-img" [alt]="card.image.alt_text" [sdxLazyload]="card.imageSources" [lazyloadIf]="inView" (lazyloaded)="card.image.isLoaded = true;" />
          </picture>
        </div>
        <figcaption class="spots-content rte" [@staggerIn]="inView">
          <h5 class="stagger-in"><b>{{card.headline}}</b></h5>
          <p class="stagger-in" [innerHTML]="card.paragraph"></p>
        </figcaption>
      </figure>
    </div>

  </div>
</div>
