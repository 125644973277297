import { Component, OnInit, ViewChild, ElementRef, Renderer2, AfterViewInit, HostListener, HostBinding } from '@angular/core';
import { CueContentType, CueElement, CueElementAware } from '@cue-core';
import { FilterItemModel, FilterModuleModel } from '@core/content-models';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CueFilterService, IFilterItem } from 'src/app/cue/cue-core/cue-filter-service';
import { AnimationStateService } from '@services/animation-state.service';
import { BaseComponent } from '@core/base.component';
import { staggerInOut } from '@core/animations';
import { MouseInput } from 'hammerjs'; // Don't Delete, Used for SSR
import { ViewStateService } from '@core/view-state.service';

@CueContentType({ types: ['filter_module'] })
@Component({
  selector: 'sdx-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  animations: [staggerInOut]

})
export class FilterComponent extends BaseComponent implements OnInit, CueElementAware, AfterViewInit {

  current: FilterItemModel = null;
  cueElement: FilterModuleModel;

  items: IFilterItem[] = [];
  isLoading = false;
  error: string = null;

  @HostBinding('class')
  get hostClasses(): string[] {
    return ['spacing-top-' + this.cueElement.spacing_top, 'spacing-bottom-' + this.cueElement.spacing_bottom];
  }

  public inView = false;

  @ViewChild('filterContainer', { static: true }) panelEl: ElementRef;
  @ViewChild('filterList', { static: true }) listEl: ElementRef;

  public listWidth: number;
  public panelWidth: number;
  public translateX = 0;

  private lastPosition = 0;

  public scrollEnabled = false;

  private resizeStream = new Subject();

  public hasFeatured = false;

  constructor(private renderer: Renderer2,
    private filterService: CueFilterService,
    private viewStateService: ViewStateService,
    private animationStateService: AnimationStateService) {
    super();
  }


  public ngOnInit() {

    this.resizeStream.pipe(debounceTime(250)).subscribe(() => this.withCalculations());

    const selectedId = this.viewStateService.getData(this.cueElement.id);

    if (selectedId) {
      this.select(this.cueElement.elements.find(n => n.id == selectedId));
    } else {
      this.select(this.cueElement.elements[0]);
    }

    this.animationStateService.disabled
      .pipe(this.takeUntilDestroyed())
      .subscribe((disabled) => this.disableAnimations = disabled);
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      this.withCalculations();
    }, (50));
  }

  @HostListener('window:resize')
  public onWindowResize() {
    this.resizeStream.next();
  }

  private withCalculations() {
    this.listWidth = this.listEl.nativeElement.clientWidth;
    this.panelWidth = this.panelEl.nativeElement.clientWidth;

    this.listWidth > this.panelWidth ? this.enableScroll() : this.disableScroll();
  }

  public disableScroll() {
    if (!this.scrollEnabled) {
      return;
    }

    this.scrollEnabled = false;
    this.renderer.setStyle(this.listEl.nativeElement, 'transform', `translateX(0)`);
  }

  public enableScroll() {
    if (this.scrollEnabled) {
      return;
    }

    this.scrollEnabled = true;
  }

  // tslint:disable-next-line: variable-name
  public panStart(_$event: MouseInput) {
    if (!this.scrollEnabled) {
      return;
    }

    this.renderer.addClass(this.panelEl.nativeElement, 'is-panning');
  }

  public panMove($event: MouseInput) {
    if (!this.scrollEnabled) {
      return;
    }

    this.translateX = this.lastPosition + $event.deltaX;
    this.renderer.setStyle(this.listEl.nativeElement, 'transform', `translateX(${this.translateX}px)`);
  }

  // tslint:disable-next-line: variable-name
  public panEnd(_event: MouseInput = null) {
    if (!this.scrollEnabled) {
      return;
    }

    this.lastPosition = this.translateX;
    this.renderer.removeClass(this.panelEl.nativeElement, 'is-panning');

    if (this.lastPosition >= 0) {
      this.lastPosition = 0;
    }

    const maxScroll = (this.listWidth - this.panelWidth) * -1;
    if (this.lastPosition < maxScroll) {
      this.lastPosition = maxScroll;
    }

    this.renderer.setStyle(this.listEl.nativeElement, 'transform', `translateX(${this.lastPosition}px)`);
  }

  // tslint:disable-next-line: variable-name
  public panCancel(_$event: MouseInput) {
    if (!this.scrollEnabled) {
      return;
    }
    this.renderer.removeClass(this.panelEl.nativeElement, 'is-panning');
  }

  public select(item: FilterItemModel) {
    this.current = item;
    this.isLoading = true;
    this.items = [];

    this.viewStateService.setData(this.cueElement.id, item.id);

    this.filterService
      .find(item.filter_item)
      .subscribe((res) => {
        this.isLoading = false;
        this.items = res || [];
        this.error = null;

        this.findFeatured();
      }, (res) => {
        this.isLoading = false;
        this.error = res.error;
      });
  }

  public findFeatured() {
    const featured = this.items.filter(n => n.isFeatured);
    if (featured.length >= 2) {

      this.hasFeatured = true;

      [0, 1].reverse().forEach(i => {
        this.items.splice(this.items.indexOf(featured[i]), 1);
        this.items.unshift(featured[i]);
      });
    } else {
      this.hasFeatured = false;
    }
  }

  public isFeatured(index: number) {
    return (this.hasFeatured && (index === 0 || index === 1));
  }

  public isTestimonial(index: number) {
    return (this.hasFeatured && index === 8 && this.items[index].testimonialModule)
      || (!this.hasFeatured && index === 9 && this.items[index].testimonialModule);
  }

  public scrollLeft() {
    this.translateX = this.lastPosition + 100;
    this.panEnd();
  }

  public scrollRight() {
    this.translateX = this.lastPosition - 100;
    this.panEnd();
  }

  public trackByFnc = (i: number, n: CueElement) => n.id;

}
