import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { NavigationComponent } from './navigation/navigation.component';
import { CTAsModule } from '../ctas/ctas.module';
import { SharedModule } from '../shared/shared.module';
import { OpacityLayerComponent } from './opacity-layer/opacity-layer.component';
import { RouterModule } from '@angular/router';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { SearchModule } from '../search/search.module';

@NgModule({
  declarations: [HeaderComponent,
    FooterComponent,
    OpacityLayerComponent,
    ToolbarComponent,
    NavigationComponent,
    MainLayoutComponent
  ],
  imports: [RouterModule, CommonModule, CTAsModule, SharedModule, SearchModule],
  exports: [HeaderComponent, FooterComponent, OpacityLayerComponent, MainLayoutComponent],
})
export class LayoutModule { }
