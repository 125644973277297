import { Directive, ElementRef, EventEmitter, HostListener, Output, Input, NgZone } from '@angular/core';

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective {

  private _clickOutsideIf = true;

  @Input()
  public get clickOutsideIf() {
    return this._clickOutsideIf;
  }
  public set clickOutsideIf(value) {
    // this._clickOutsideIf = value;
    this.zone.runOutsideAngular(() => { setTimeout(() => this._clickOutsideIf = value, 0); });
  }

  @Output()
  public clickOutside = new EventEmitter<Event>();

  constructor(private zone: NgZone, private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event', '$event.target'])
  @HostListener('document:touchstart', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: Node): void {
    if (this.clickOutsideIf && !this.elementRef.nativeElement.contains(targetElement)) {
      this.clickOutside.next(event);
    }
  }
}
