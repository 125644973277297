import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UrlSegment } from '@angular/router';
import { throwError } from 'rxjs';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CUE_HEADLESS_HOST } from './cue-content';
import { CueContentMapper } from './cue-content-mapper';
import { CueContentModelProvider } from './cue-content-model-provider';
import { ICuePageDto } from './cue-interfaces';
import {makeStateKey, TransferState} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class CueContentService {
  constructor(private http: HttpClient,
    private transferState: TransferState,

    private contentModelProvider: CueContentModelProvider,

    @Inject(CUE_HEADLESS_HOST) private host) { }

  public getContent(urlSegments: UrlSegment[]) {
    let path = urlSegments.join('/');

    if (!path) {
      path = 'home';
    }

    // const URL_KEY = makeStateKey<ICuePageDto>('url-' + path);

    // if (this.transferState.hasKey(URL_KEY)) {
    //     const course = this.transferState.get<ICuePageDto>(URL_KEY, null);
    //     this.transferState.remove(URL_KEY);
    //     return of(course);
    // }


    return this.http
      .get<ICuePageDto>(`${this.host}/${path}`, { observe: 'response' })
      .pipe(map((x) => new CueContentMapper(this.contentModelProvider, x.body, x.status).getContent()))
      .pipe(catchError((response: HttpErrorResponse) => {
        if (response.status === 404 && !!response.error) {
          return of(new CueContentMapper(this.contentModelProvider, response.error, response.status).getContent());
        }
        return throwError(response);
      }));


  }
}
