<div [@staggerIn]="inView" class="solution-card" [ngClass]="[theme, card.ribbonTheme, card.link ? 'block-link' : '']">
  <h5 class="card-title">{{card.headline}}</h5>
  <p class="card-paragraph " [innerHTML]="card.paragraph"></p>
  <hr>
  <ul class="solution-card-list">
    <li class="solution-card-list-item stagger-in" *ngFor="let bullet of card.bulletin.elements; trackBy: trackByFnc">
      <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.10627 6.80957L10.8132 0.102657C10.9501 -0.0342189 11.1782 -0.0342189 11.3151 0.102657L12.1363 0.923912C12.2732 1.06079 12.2732 1.28891 12.1363 1.42579L4.34961 9.2125C4.21273 9.34938 3.99982 9.34938 3.86294 9.2125L0.0912505 5.44081C-0.0304168 5.31915 -0.0304168 5.09102 0.0912505 4.95414L0.927714 4.13289C1.06459 3.99601 1.29272 3.99601 1.41438 4.13289L4.10627 6.80957Z" fill="#141A5B" />
      </svg>
      <span> {{bullet.bulletin}}</span>
    </li>
  </ul>
  <sdx-cta-tertiary class="mb-0" *ngIf="card.link" [theme]="ctaTheme" [text]="card.link.text" [href]="card.link.link" [isHovered]="isHovered"></sdx-cta-tertiary>

  <a *ngIf="card.link && !(card.link.link | isExternal)" class="block-link-overlay" [routerLink]="card.link.link" [title]="card.link.text" [attr.target]="card.link.target" [attr.rel]="card.link.rel" (mouseover)="isHovered = true" (mouseout)="isHovered = false"><span class="sr-only">{{card.link.text}}</span></a>
  <a *ngIf="card.link && (card.link.link | isExternal)" class="block-link-overlay" [href]="card.link.link" [title]="card.link.text" [attr.target]="card.link.target" [attr.rel]="card.link.rel" (mouseover)="isHovered = true" (mouseout)="isHovered = false"><span class="sr-only">{{card.link.text}}</span></a>

</div>
