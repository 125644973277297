import { Component, ChangeDetectionStrategy, HostBinding, OnInit } from '@angular/core';
import { CueContentType, CueElementAware } from '@cue-core';
import { AnimationStateService } from '@services/animation-state.service';
import { BaseComponent } from '@core/base.component';
import { ParagraphSmallModel } from '@core/content-models';
import { staggerIn } from '@core/animations';

@CueContentType({ types: ['paragraph_small'] })
@Component({
  selector: 'sdx-paragraph-small',
  template: `<div (sdxInView)="inView = true" [@staggerIn]="inView"><p class="stagger-in p-small" [ngClass]="classes" [innerHTML]="cueElement.paragraph"></p></div>`,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [staggerIn]
})
export class ParagraphSmallComponent extends BaseComponent implements OnInit, CueElementAware {
  cueElement: ParagraphSmallModel;

  @HostBinding('class.p-horizontal')
  get hostBinding(): boolean {
    return this.cueElement.level === 0;
  }

  @HostBinding('class')
  get hostClasses(): string[] {
    return ['spacing-top-' + this.cueElement.spacing_top, 'spacing-bottom-' + this.cueElement.spacing_bottom];
  }

  public inView = false;

  constructor(private animationStateService: AnimationStateService) {
    super();
  }

  ngOnInit() {
    this.animationStateService.disabled
      .pipe(this.takeUntilDestroyed())
      .subscribe(disabled => this.disableAnimations = disabled);
  }

  get classes(): string[] {
    const arr = [];
    if (this.cueElement.isCentered) {
      arr.push('text-center');
    }
    return arr;
  }
}
