import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ICueBreadCrumbItem } from '@cue-core';

@Component({
  selector: 'sdx-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent {

  @Input()
  items: ICueBreadCrumbItem[];
}
