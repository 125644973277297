import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, shareReplay } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public dictionary: Map<string, string>;
  public observable: Observable<any>;

  public unregistered: string[] = [];

  constructor(private httpClient: HttpClient) {}

  public locale: string;

  public flattenObj(obj: {}, parent: string, res = {}) {
    // tslint:disable-next-line: forin
    for (const key in obj) {
      const propName = parent ? parent + '_' + key : key;
      if (typeof obj[key] === 'object') {
        this.flattenObj(obj[key], propName, res);
      } else {
        res[propName] = obj[key];
      }
    }
    return res;
  }

  public setLanguage(locale: string) {

    this.locale = locale;

    this.observable = this.httpClient
      .get<any>(`${environment.apiUrl}/language/${locale}`)
      .pipe(
        tap((data) => {
          const flatDictionary = this.flattenObj(data.results, '');
          this.dictionary = new Map<string, string>();
          for (const prop in flatDictionary) {
            if (flatDictionary.hasOwnProperty(prop)) {
              this.dictionary.set(prop.toLowerCase(), flatDictionary[prop]);
            }
          }
        })
      )
      .pipe(shareReplay(1));

    this.observable.subscribe(() => {});
  }

  public get(key: string) {
    if (this.dictionary.has(key)) {
      return this.dictionary.get(key);
    } else {
      if (!this.unregistered.includes(key)) {
        this.unregistered.push(key);
      }
      return key;
    }
  }
}
