export interface ICookieInformationConcent {
  website_uuid: string;
  timestamp: Date;
  consent_url: string;
  consent_website: string;
  consent_domain: string;
  user_uid: string;
  consents_approved: string[];
  consents_denied: any[];
  user_agent: string;
}

export enum CookieConcentType {
  Necessary = 'cookie_cat_necessary',
  Functonal = 'cookie_cat_functional',
  Statistic = 'cookie_cat_statistic',
  Marketing = 'cookie_cat_marketing',
}

export class CookieInformation {

  private static getCookie(): ICookieInformationConcent | null {
    const cookieName = 'CookieInformationConsent=';
    const allCookieArray = document.cookie.split(';');
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < allCookieArray.length; i++) {
      const temp = allCookieArray[i].trim();
      if (temp.indexOf(cookieName) === 0) {
        const data = temp.substring(cookieName.length, temp.length);
        const json = JSON.parse(decodeURIComponent(data));
        return json;
      }
    }
    return null;
  }

  public static hasConcent(type: CookieConcentType) {
    const cookie = CookieInformation.getCookie();
    return (cookie !== null && cookie.consents_approved.includes(type));
  }
}
