<div class="highlight-stats-container" (sdxInView)="inView = true">
  <div class="row v-gutters justify-content-center">

    <div class="col-6 col-md-3" *ngFor="let n of cueElement.elements; trackBy: trackByFnc">
      <div class="rte">
        <h3 class="text-center"><b><sdx-counter [text]="n.headline" [countIf]="inView && !disableAnimations"></sdx-counter></b></h3>
      </div>
      <div class="highlight-stats-separator"></div>
      <div class="text-center rte">
        <p>{{n.paragraph}}</p>
      </div>
    </div>

  </div>
</div>
