import { Component, Input, OnChanges } from '@angular/core';
import { ICueNavChild, ICueNavigation, ICueNavItem } from '@cue-core';
import { OpacityLayer, OpacityLayerService } from '@layout/opacity-layer/opacity-layer.service';
import { Breakpoint, BreakpointService } from '@services/breakpoint.service';
@Component({
  selector: 'sdx-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnChanges {

  @Input()
  public data: ICueNavigation;

  public isDesktop = false;

  public link: ICueNavItem;
  public items: ICueNavItem[];

  constructor(private breakpointService: BreakpointService) {
    this.breakpointService.active.subscribe((breakpoints) => (this.isDesktop = breakpoints.includes(Breakpoint.LG_MIN)));
  }

  ngOnChanges(): void {
    // Ensures that the header data is not mutated, first on the sever and then statetranfered
    const items = [... this.data.top_header];

    this.link = items.splice(0, 1)[0].item;
    this.items = items.filter(n => !!n.item).map(n => n.item);
  }

  public trackByIndex = (i: number, n: ICueNavChild) => i;

}
