import { Component, OnInit } from '@angular/core';
import { CueContent, CueContentAware, CueContentType, CueElement } from '@cue-core';
import { BackgroundImageModuleModel } from '@cue-models';

@CueContentType({ types: ['promotional'] })
@Component({
  selector: 'sdx-page-campaign',
  templateUrl: './page-campaign.component.html',
  styleUrls: ['./page-campaign.component.scss'],
})
export class PageCampaignComponent implements OnInit, CueContentAware {

  public backgroundImageModule: BackgroundImageModuleModel;
  public elements: CueElement[];

  public cueContent: CueContent;

  ngOnInit(): void {
    this.backgroundImageModule = this.cueContent.elements.find(n => n instanceof BackgroundImageModuleModel) as BackgroundImageModuleModel;
    this.elements = this.cueContent.elements.filter(n => !(n instanceof BackgroundImageModuleModel));
  }

  public trackByFnc = (i: number, n: CueElement) => n.id;

}
