import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { CueContentType, CueElementAware } from '@cue-core';
import { HtmlSnippetModel } from '@core/content-models';
import { isPlatformBrowser } from '@angular/common';

@CueContentType({ types: ['html_snippet'] })
@Component({
  selector: 'sdx-html-snippet',
  templateUrl: './html-snippet.component.html',
  styleUrls: ['./html-snippet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtmlSnippetComponent implements AfterViewInit, CueElementAware {
  cueElement: HtmlSnippetModel;

  @HostBinding('class')
  get hostClasses(): string[] {
    return ['spacing-top-' + this.cueElement.spacing_top, 'spacing-bottom-' + this.cueElement.spacing_bottom];
  }

  public inView = false;

  @ViewChild('container')
  container: ElementRef<HTMLElement>;


  constructor(@Inject(PLATFORM_ID) private readonly platformId: object) {}


  public ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
        const nodes = this.htmlToNodes(this.cueElement.html_snippet);
        this.addAsChildren(this.container.nativeElement, nodes);
    }
  }

  private htmlToNodes(html: string) {
    const nodeBuilder: Node[] = [];

    // create a temporary container and insert provided HTML code
    const container = document.createElement('div');
    container.innerHTML = html;

    // get all child elements and clone them in the target element
    const nodes = Array.from(container.childNodes);
    const scriptNodes = nodes.filter((n) => n.nodeName === 'SCRIPT') as HTMLScriptElement[];
    const contetnNodes = nodes.filter((n) => n.nodeName !== 'SCRIPT');

    contetnNodes.forEach((node) => nodeBuilder.push(node.cloneNode(true)));

    scriptNodes.forEach((ref) => {
      const script = document.createElement('script');
      Array.from(ref.attributes).forEach(({ name, value }) => script.setAttribute(name, value));
      script.innerHTML = ref.innerHTML;
      nodeBuilder.push(script);
    });

    return nodeBuilder;
  }

  public addAsChildren(container: HTMLElement, nodes: Node[]) {
    nodes.forEach((n) => container.appendChild(n));
  }
}
