import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, PLATFORM_ID } from '@angular/core';

@Directive({
  selector: '[sdxInView]',
})
export class InViewDirective implements OnInit, OnDestroy {
  private observer: IntersectionObserver;

  private isIntersecting = false;

  @Input()
  public inViewOptions: IntersectionObserverInit = {
    rootMargin: '0px 0px',
    threshold: 0,
  };

  @Output('sdxInView')
  public inView$ = new EventEmitter<boolean>();

  constructor(@Inject(PLATFORM_ID) private readonly platformId: object, private hostElement: ElementRef) { }

  ngOnInit() {

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting !== this.isIntersecting) {
          this.inView$.emit(entry.isIntersecting);
          this.isIntersecting = entry.isIntersecting;
        }
      });
    }, this.inViewOptions);

    this.observer.observe(this.hostElement.nativeElement);
  }

  public ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.observer.disconnect();
    }
  }
}
