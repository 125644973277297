import { flatten } from '@angular/compiler';
import { Inject, Injectable, Type } from '@angular/core';
import { Resolve } from '@angular/router';
import { CUE_CONTENT_TYPES, CueContentType } from './cue-content-type';

@Injectable({
  providedIn: 'root',
})
export class CueContentTypeProvider {
  private contentTypeDefMap: Map<string, ContentTypeDef> = new Map<string, ContentTypeDef>();

  constructor(@Inject(CUE_CONTENT_TYPES) documentTypes: Type<any>[][]) {
    const types = flatten(documentTypes);

    for (const documentType of types) {
      const metadata = Reflect.getMetadata('cueContentType', documentType) as CueContentType;

      if (metadata && metadata.types) {
        metadata.types.forEach((type) => {
          if (this.contentTypeDefMap.has(type)) {
            throw Error(documentType.name + ' is defined multiple times with the content type type "' + type + '"');
          }

          this.contentTypeDefMap.set(type, {
            component: documentType,
            type,
            resolveTypes: metadata.resolve,
            canActivateTypes: metadata.canActivate,
          });
        });
      } else {
        throw Error(
          'Cannot register component. ' + documentType.name + ' is not at content type. Use CueContentType decorator on the component'
        );
      }
    }
  }

  public get(type: string) {
    return this.contentTypeDefMap.get(type);
  }

  public has(type: string) {
    return this.contentTypeDefMap.has(type);
  }

  public getAll() {
    return Array.from(this.contentTypeDefMap.values());
  }
}

export interface ContentTypeDef {
  type: string;
  component: Type<any>;
  resolveTypes?: { [name: string]: Type<Resolve<any>> };
  // resolveProviders?: ResolveProvider[];
  canActivateTypes?: Type<any>[];
}

export interface ResolveProvider {
  dataKey: string;
  resolveType: Type<Resolve<any>>;
}
