import { ExternalLinkModel, InternalLinkModel } from '@core/content-models';
import { CueContent, CueElement } from './cue-content';
import { CueContentModelProvider } from './cue-content-model-provider';
import { ICueElementMapper } from './cue-element-mapper.interface';
import { ICueElementDto, ICuePageDto } from './cue-interfaces';

export class IAnnotationLink {
  newWindow: boolean;
  noFollow: boolean;
  href: string;
}

export class CueContentMapper implements ICueElementMapper {
  private content: CueContent;
  private elementMap = new Map<string, any>();
  private annotationLinks = new Map<string, IAnnotationLink>();

  private disallowedTypes = [
    'internal_link',
    'external_link',
    'filter_feature_card_module',
    'filter_resource_card_module',
    'filter_testimonial_module'
  ];

  constructor(private contentModelProvider: CueContentModelProvider, content: ICuePageDto, statusCode: number) {
    // const t0 = performance.now();

    try {
      this.content = new CueContent(content, statusCode);
    } catch (error) {
      console.error('Failed to create CueElement: ', error);
    }

    const childSet = new Set<string>();
    content.elements.forEach((element) => {

      if (element.type === 'external_link') {
        const model = new ExternalLinkModel(this, element);
        this.annotationLinks.set(element.id, model);
        return;
      }

      if (element.type === 'internal_link' && element.relation !== null) {
        const model = new InternalLinkModel(this, element);
        this.annotationLinks.set(element.relation.id, model);
        return;
      }

      if (!element.id) {
        console.error(`Element: has no Id!`, element);
        return;
      }

      this.elementMap.set(element.id, element);
      if (!!element.children) {
        element.children.forEach((id) => childSet.add(id));
      }
    });

    const rootElements = content.elements
      .filter((el) => !this.disallowedTypes.includes(el.type) && !childSet.has(el.id))
      .map((n) => this.mapElement(n, null));

    this.content.elements = rootElements;

    // const t1 = performance.now();

    // if (window.location.host.includes('localhost') || window.location.hostname.includes('illumi.dk')) {
    //   console.log('Indexing elements took: ' + (t1 - t0) + ' milliseconds.', this.content);
    // }
  }

  private mapElement(elementDto: ICueElementDto, parent: CueElement) {
    try {
      if (this.contentModelProvider.has(elementDto.type)) {
        const ref = this.contentModelProvider.get(elementDto.type);
        return new ref.model(this, elementDto, parent);
      }
      return new CueElement(this, elementDto, parent);

    } catch (error) {
      console.error(error, elementDto);
    }
  }

  public getElement(id: string, parent: CueElement) {

    // Because of stupid API / FAQ module
    if (typeof id !== 'string') {
      return;
    }

    const elementDto = this.elementMap.get(id);

    if (!!elementDto) {
      return this.mapElement(elementDto, parent);
    }
    console.error(`Element: ${id} not found!`);
  }

  public getContent() {
    return this.content;
  }

  public getLink(id: string) {
    return this.annotationLinks.get(id);
  }
}
