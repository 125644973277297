import { HostBinding } from '@angular/core';
import { Component } from '@angular/core';
import { CueContentType, CueElement, CueElementAware } from '@cue-core';
import { ColumnModuleModel } from '@core/content-models';

@CueContentType({ types: ['column_module'] })
@Component({
  selector: 'sdx-column',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.scss'],
})
export class ColumnComponent implements CueElementAware {
  cueElement: ColumnModuleModel;

  @HostBinding('class')
  get hostClasses(): string[] {
    return ['spacing-top-' + this.cueElement.spacing_top, 'spacing-bottom-' + this.cueElement.spacing_bottom];
  }

  public trackByFnc = (i: number, n: CueElement) => n.id;
}

