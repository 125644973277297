import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

document.addEventListener('DOMContentLoaded', () => {
  const msBrowser = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(
    window.navigator.userAgent
  );

  const msVersion: number | null = (() => {
    return msBrowser ? parseInt(msBrowser[2], 10) : null;
  })();

  // Add ie-(version) class for css workarounds
  if (msVersion !== null) {
    document.body.classList.add('ie-' + msVersion);
  }
  let redirect = false;

  if (msVersion !== null && msVersion <= 11) {
    redirect = confirm(`Your browser, Internet Explorer ${msVersion}, is not supported!\n
Please upgrade to the latest Goolge Chrome, Mozilla Firefox, Safari or Microsoft Edge.`);
  }

  if (msBrowser !== null && msBrowser.includes('Edge/17')) {
    redirect = confirm(`Your version of Microsoft Edge is not supported!\n
Please upgrade to the latest Goolge Chrome, Mozilla Firefox, Safari or Microsoft Edge.`);
  }


  if (redirect) {
    window.location.href = 'https://www.google.com/chrome/';
}
});


if (environment.production) {
  enableProdMode();
}


document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
});
