import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageStandardComponent } from './page-standard/page-standard.component';
import { PageCampaignComponent } from './page-campaign/page-campaign.component';
import { LayoutModule } from '../layout/layout.module';
import { ComponentsModule } from '../components/components.module';
import { CTAsModule } from '../ctas/ctas.module';
import { PageErrorComponent } from './page-error/page-error.component';
import { CueCoreModule } from '../cue/cue-core/cue-core.module';
import { SharedModule } from '@shared/shared.module';
import { SearchModule } from '../search/search.module';

export const CUE_PAGE_TYPES = [PageStandardComponent, PageCampaignComponent];

@NgModule({
  declarations: [CUE_PAGE_TYPES, PageErrorComponent],
  imports: [CommonModule, LayoutModule, CueCoreModule, ComponentsModule, CTAsModule, SharedModule, SearchModule],
  exports: [PageStandardComponent, PageCampaignComponent, PageErrorComponent],
})
export class PagesModule {}
