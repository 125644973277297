import { Component, HostBinding, OnInit } from '@angular/core';
import { CueContentType, CueElement, CueElementAware } from '@cue-core';
import { HeroModuleModel } from '@core/content-models';
import { AnimationStateService } from '@services/animation-state.service';
import { BaseComponent } from '@core/base.component';
import { staggerIn } from '@core/animations';

@CueContentType({ types: ['hero_module'] })
@Component({
  selector: 'sdx-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
  animations: [staggerIn]
})
export class HeroComponent extends BaseComponent implements OnInit, CueElementAware {

  cueElement: HeroModuleModel;

  @HostBinding('class')
  get hostClasses(): string[] {
    return ['spacing-top-' + this.cueElement.spacing_top, 'spacing-bottom-' + this.cueElement.spacing_bottom];
  }

  public inView = false;

  constructor(private animationStateService: AnimationStateService) {
    super();
  }

  ngOnInit() {
    this.animationStateService.disabled
      .pipe(this.takeUntilDestroyed())
      .subscribe(disabled => this.disableAnimations = disabled);
  }

  public trackByFnc = (i: number, n: CueElement) => n.id;
}
