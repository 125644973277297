import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'sdx-cta-chip',
  templateUrl: './cta-chip.component.html',
  styleUrls: ['./cta-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CtaChipComponent {
  @Input()
  public theme: 'default' | 'light' = 'default';

  @Input()
  public text;
}
