import { ComponentFactoryResolver, ComponentRef, Inject, Injectable, InjectionToken, Type, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, ExtraOptions, Router, Routes } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ContentTypeDef, CueContentTypeProvider, CueContentAccessor, CueContentAware, CueContent } from '../cue-core';

export const CUE_ROUTER_CONFIGURATION = new InjectionToken<CueRouteConfiguration>('CUE_ROUTER_CONFIGURATION');

export interface CueRouteConfiguration {
  /**
   *  Override cue routes
   */
  routes?: Routes;

  /**
   *  Children routes for cue content types
   */
  childRoutes?: Routes;
  fallback?: Type<any>;
  options?: ExtraOptions;
}

export interface DocumentTypeRoute {
  contentType: ContentTypeDef;
  isFallback: boolean;
  statusCode: number;
}

@Injectable()
export class CueRouter {
  constructor(
    private contentTypeProvider: CueContentTypeProvider,
    private contentAccessor: CueContentAccessor,
    private componentFactoryResolver: ComponentFactoryResolver,
    @Inject(CUE_ROUTER_CONFIGURATION) public configuration,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public get route$() {
    return this._route$.asObservable();
  }

  // tslint:disable-next-line: variable-name
  private _route$ = new BehaviorSubject<DocumentTypeRoute>(null);

  private componentRef: ComponentRef<CueContentAware>;

  public dispose() {
    this._route$.next(null);
  }

  public routeSnapshot() {
    return this._route$.getValue();
  }

  public get navigated() {
    return this._route$.getValue() != null;
  }

  public setRoute(content: CueContent, statusCode = 200) {
    this.contentAccessor.current$.next(content);

    if (!this.contentTypeProvider.has(content.type)) {
      console.error(content.type + ' is not referenced as a type.');
      return;
    }

    const contentTypeDef = this.contentTypeProvider.get(content.type);

    const route = { contentType: contentTypeDef, isFallback: false, statusCode } as DocumentTypeRoute;

    this._route$.next(route);

    return true;
  }

  public setFallbackRoute(statusCode: number) {
    this.contentAccessor.current$.next(null);

    if (this.configuration.fallback) {
      const route = {
        contentType: {
          component: this.configuration.fallback,
          type: '',
        },
        isFallback: true,
        statusCode,
      } as DocumentTypeRoute;
      this._route$.next(route);
    } else {
      this._route$.next(null);
    }
  }

  public createAndRenderComponent(viewContainerRef: ViewContainerRef, route: DocumentTypeRoute): void {
    if (!route || !route.contentType || !route.contentType.component) {
      viewContainerRef.clear();
      return;
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(route.contentType.component);

    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory, 0);
    this.componentRef.instance.cueContent = this.contentAccessor.current$.getValue();
  }
}
