import { Component, OnInit } from '@angular/core';
import { CueContentType, CueElement, CueElementAware } from '@cue-core';
import { SpotItemModuleModel } from '@core/content-models';
import { AnimationStateService } from '@services/animation-state.service';
import { BaseComponent } from '@core/base.component';
import { staggerIn } from '@core/animations';
@CueContentType({ types: ['spot_item_module'] })
@Component({
  selector: 'sdx-highlight-spots',
  templateUrl: './highlight-spots.component.html',
  styleUrls: ['./highlight-spots.component.scss'],
  animations: [staggerIn]
})
export class HighlightSpotsComponent extends BaseComponent implements OnInit, CueElementAware {

  public cueElement: SpotItemModuleModel;

  public inView = false;

  constructor(private animationStateService: AnimationStateService) {
    super();
  }

  ngOnInit() {
    this.animationStateService.disabled
      .pipe(this.takeUntilDestroyed())
      .subscribe((disabled) => this.disableAnimations = disabled);
  }

  public trackByFnc = (i: number, n: CueElement) => n.id;


}
