<div class="resource-card" [ngClass]="[theme, isLarge ? 'is-large' : '', card.image ? 'with-image' : 'without-image', card.link ? 'block-link' : '']" (sdxInView)="inView = true">

  <sdx-cta-chip class="resource-card-label" *ngIf="card.label" [text]="card.label" [theme]="chipTheme"></sdx-cta-chip>

  <picture class="resource-card-picture lazyload-picture ratio-16-9" *ngIf="card.imageSources" [ngClass]="{'is-ready': card.image.isLoaded}">
    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" class="lazyload-img" [alt]="card.image.alt_text" [sdxLazyload]="card.imageSources" [lazyloadIf]="inView" (lazyloaded)="card.image.isLoaded = true;" />
  </picture>

  <div class="resource-card-text">
    <h5 class="card-title">{{card.headline}}</h5>
    <p class="card-paragraph" [innerHTML]="card.paragraph"></p>
  </div>

  <div class="resource-card-footer">
    <p class="resouce-card-readtime">{{card.timeToRead}} {{ "global_minread" | translate | async }}</p>
    <sdx-cta-tertiary *ngIf="card.link" [theme]="ctaTheme" [text]="card.link.text" [href]="card.link.link" [isHovered]="isHovered"></sdx-cta-tertiary>
  </div>

  <a *ngIf="card.link && !(card.link.link | isExternal)" class="block-link-overlay" [routerLink]="card.link.link" [title]="card.link.text" [attr.target]="card.link.target" [attr.rel]="card.link.rel" (mouseover)="isHovered = true" (mouseout)="isHovered = false"><span class="sr-only">{{card.link.text}}</span></a>
  <a *ngIf="card.link && card.link.link | isExternal" class="block-link-overlay" [href]="card.link.link" [title]="card.link.text" [attr.target]="card.link.target" [attr.rel]="card.link.rel" (mouseover)="isHovered = true" (mouseout)="isHovered = false"><span class="sr-only">{{card.link.text}}</span></a>

</div>
