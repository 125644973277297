<section class="container-03" (sdxInView)="inView = $event">
  <div class="theme-default slider-wrapper">

    <div class="slider-header">
      <sdx-cue-content-portal *ngFor="let e of cueElement.elements; trackBy: trackByFnc" [content]="e"></sdx-cue-content-portal>
    </div>

    <ilx-slider *ngIf="cueElement.slider.slides.length > 0" [itemsBefore]="cueElement.slider.slides.length" [itemsAfter]="cueElement.slider.slides.length" (indexChange)="setIndex($event)">

      <ilx-slide class="slider-item" [ngClass]="{'is-current' : i === currentIndex}" *ngFor="let item of multiply(cueElement.slider.slides); let i = index; trackBy: trackByIndex">
        <figure>
          <picture class="image-picture" [ngClass]="{'is-ready': ready}">
            <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" class="image-img" [alt]="item.image.alt_text" (sdxInView)="inView = $event" [sdxLazyload]="item.imageSources" [lazyloadIf]="inView" (lazyloaded)="ready =true;" />
          </picture>
          <figcaption class="slider-caption">
            <h5>{{item.headline}}</h5>
            <p [@animateText]="i === currentIndex ? true : false">{{item.paragraph}}</p>
            <sdx-cta-tertiary *ngIf="item.link" [@animateText]="i === currentIndex ? true : false" [theme]="'default'" [text]="item.link.text" [href]="item.link.link"></sdx-cta-tertiary>
          </figcaption>
        </figure>
      </ilx-slide>

    </ilx-slider>
  </div>

</section>
