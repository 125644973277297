import { Component, OnDestroy, OnInit } from '@angular/core';
import { ViewStateService } from '@core/view-state.service';
import { CueContent } from '@cue-core';
import { OpacityLayerService } from '@layout/opacity-layer/opacity-layer.service';
import { LanguageService } from '@services/language.service';
import { MetaService } from '@services/meta.service';
import { query } from 'express';
import { ISearchResults } from 'src/app/search/search.service';

@Component({
  selector: 'sdx-search',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss']
})
export class SearchPageComponent implements OnInit {


  public isLoading = false;

  public results: ISearchResults;

  public query: string;

  constructor(
    private languageService: LanguageService,
    private metaService: MetaService,
    private viewStateService: ViewStateService,
  ) {

    this.languageService.setLanguage('en_GB');
    this.metaService.setMeta(new CueContent({
      title: 'Search',
      type: 'CustomSearch',
      elements: [],
      metaData: {
        meta_title: 'Search',
        meta_description: 'Search Results',
        meta_robots: 'noindex, nofollow',
        locale: 'en_GB',
        type: 'CustomSearch',
        canonical_url: null,
        meta_author: 'Search',
      },
      navigation: null
    }, 200));
  }

  ngOnInit() {
    this.query = this.viewStateService.getData('SearchQuery');
  }

  public updateQuery(q: string) {
    this.query = q;
    this.viewStateService.setData('SearchQuery', q);
  }

  public back(): void {
    history.back();
  }

}
