import { Component, HostBinding, HostListener, ViewChild, ElementRef, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { CueContentType, CueElementAware } from '@cue-core';
import { VimeoModuleModel } from '@core/content-models';
import Player, { Options } from '@vimeo/player';
import { AnimationStateService } from '@services/animation-state.service';
import { BaseComponent } from '@core/base.component';
import { isPlatformBrowser } from '@angular/common';
import { CookieConcentType, CookieInformation } from './cookie-information';


@CueContentType({ types: ['vimeo_module'] })
@Component({
  selector: 'sdx-vimeo',
  templateUrl: './vimeo.component.html',
  styleUrls: ['./vimeo.component.scss'],
})
export class VimeoComponent extends BaseComponent implements OnInit, CueElementAware {
  public cueElement: VimeoModuleModel;

  @HostBinding('class')
  get hostClasses(): string[] {
    return ['spacing-top-' + this.cueElement.spacing_top, 'spacing-bottom-' + this.cueElement.spacing_bottom];
  }

  @HostListener('class.is-cued')
  public isCued = false;

  @HostListener('class.is-buffering')
  public isBuffering = false;

  @HostListener('class.is-playing')
  public isPlaying = false;

  @HostListener('class.is-paused')
  public isPaused = false;

  @HostListener('class.is-ended')
  public isEnded = false;

  @ViewChild('playerContainer', { static: true })
  public playerContainer: ElementRef;

  private player!: Player;

  public inView = false;

  get isReady(): boolean {
    return this.cueElement.isSilent ? this.isPlaying : this.hasConcent;
  }

  public hasConcent = false;

  constructor(@Inject(PLATFORM_ID) private readonly platformId: object, private animationStateService: AnimationStateService) {
    super();
  }

  ngOnInit() {
    this.animationStateService.disabled
      .pipe(this.takeUntilDestroyed())
      .subscribe((disabled) => this.disableAnimations = disabled);


    if (isPlatformBrowser(this.platformId)) {
      this.checkReadyState();
    }

    let interval = null;
    if (isPlatformBrowser(this.platformId)) {
      interval = setInterval(() => {
        this.checkReadyState();
        if (this.hasConcent) {
          clearInterval(interval);
        }
      }, 100);
    }
  }

  public play() {
    this.player.play();
  }

  public checkReadyState() {
    this.hasConcent = CookieInformation.hasConcent(CookieConcentType.Functonal);
    if (this.hasConcent && this.inView) {
      this.createPlayer();
    }
  }

  private createPlayer() {
    const options: Options = {
      dnt: true,
      background: this.cueElement.isSilent,
      responsive: true,
      playsinline: true,
      byline: false,
      title: false,
    };

    if (this.cueElement.vimeoId.includes('/')) {
      options.url = this.cueElement.vimeoId;
    } else {
      options.id = parseInt(this.cueElement.vimeoId, 10);
    }

    this.player = new Player(this.playerContainer.nativeElement, options);

    this.player.on('bufferstart', () => (this.isBuffering = true));

    this.player.on('bufferend', () => (this.isBuffering = false));

    this.player.on('ended', () => {
      this.clearStates();
      this.isEnded = true;
    });

    this.player.on('pause', () => {
      this.clearStates();
      this.isPaused = true;
    });

    this.player.on('play', () => {
      this.clearStates();
      this.isPlaying = true;
    });
  }


  private clearStates() {
    this.isCued = false;
    this.isBuffering = false;
    this.isPlaying = false;
    this.isPaused = false;
    this.isEnded = false;
  }

  // tslint:disable-next-line: variable-name
  public cameInView(_$event: boolean) {
    if (this.inView) {
      return;
    }
    this.inView = true;

    if (isPlatformBrowser(this.platformId)) {
      this.checkReadyState();
    }

  }

  public openCookieDialog() {
    if (((window as any).CookieConsent.show)) {
      (window as any).CookieConsent.show();
    } else {
      console.log('Cookie Concent is not loaded!')
    }
  }
}
