import { Component, Input, OnInit } from '@angular/core';
import { ISearchResults } from '../search.service';

@Component({
  selector: 'sdx-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent {

  @Input()
  public results: ISearchResults;

  @Input()
  public query: string;

  public isArray(v: any) :boolean {
    return Array.isArray(v);
  }

}
