import { Component, HostBinding, OnInit } from '@angular/core';
import { CueContentType, CueElementAware } from '@cue-core';
import { LinkModel } from '@core/content-models';
import { AnimationStateService } from '@services/animation-state.service';
import { BaseComponent } from '@core/base.component';
import { staggerIn } from '@core/animations';

@CueContentType({ types: ['link'] })
@Component({
  selector: 'sdx-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  animations: [staggerIn]
})
export class LinkComponent extends BaseComponent implements OnInit, CueElementAware {

  public cueElement: LinkModel;

  @HostBinding('class')
  get hostClasses(): string[] {
    const arr = [];
    if (this.cueElement.isCentered) {
      arr.push('is-centered');
    }
    if (this.cueElement.level === 0) {
      arr.push('p-horizontal');
    }
    return arr;
  }

  public inView = false;

  constructor(private animationStateService: AnimationStateService) {
    super();
  }

  ngOnInit() {
    this.animationStateService.disabled
      .pipe(this.takeUntilDestroyed())
      .subscribe((disabled) => this.disableAnimations = disabled);
  }
}
