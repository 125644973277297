import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FeatureCardModel, FeatureCardModuleModel, ResourceCardModel, ResourceCardModuleModel, TestimonialModuleModel } from '@cue-models';
import { map } from 'rxjs/operators';
import { CUE_HEADLESS_HOST } from './cue-content';
import { CueContentModelProvider } from './cue-content-model-provider';
import { CueFilterMapper } from './cue-filter-mapper';
import { ICueFilterResponseDto, ICueFitlerDto } from './cue-interfaces';

export interface IFilterItem {
  isFeatured: boolean;
  featureCard: FeatureCardModel;
  resourceCard: ResourceCardModel;
  testimonialModule: TestimonialModuleModel;
}

@Injectable({
  providedIn: 'root'
})
export class CueFilterService {

  constructor(private http: HttpClient, private contentModelProvider: CueContentModelProvider, @Inject(CUE_HEADLESS_HOST) private host) { }

  public find(title: string) {

    return this.http.get<ICueFilterResponseDto>(`${this.host}/tags/${title}`)
      .pipe(map((x) => x.results?.map(n => {
        const mapped = new CueFilterMapper(this.contentModelProvider, n).getElements();
        return {
          isFeatured: n.is_featured,
          resourceCard: (mapped.find(n1 => n1 instanceof ResourceCardModuleModel) as ResourceCardModuleModel)?.cards[0],
          featureCard: (mapped.find(n1 => n1 instanceof FeatureCardModuleModel) as FeatureCardModuleModel)?.cards[0],
          testimonialModule: mapped.find(n1 => n1 instanceof TestimonialModuleModel) as TestimonialModuleModel
        } as IFilterItem;
      })))
      .pipe(map((x) => x.filter(n => n.resourceCard || n.featureCard)));
  }
}
