import { HostBinding, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Component } from '@angular/core';
import { CueContentType, CueElementAware } from '@cue-core';
import { ArticleHeaderModel } from '@core/content-models';
import { isPlatformBrowser } from '@angular/common';

@CueContentType({ types: ['article_header'] })
@Component({
  selector: 'sdx-article-header',
  templateUrl: './article-header.component.html',
  styleUrls: ['./article-header.component.scss'],
})
export class ArticleHeaderComponent implements CueElementAware, OnInit{

  cueElement: ArticleHeaderModel;

  public url = '';

  constructor(@Inject(PLATFORM_ID) private readonly platformId: object) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.url = encodeURIComponent(window.location.href);
    }
  }

  @HostBinding('class')
  get hostClasses(): string[] {
    return ['spacing-top-' + this.cueElement.spacing_top, 'spacing-bottom-' + this.cueElement.spacing_bottom];
  }

}
