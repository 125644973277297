import {
  Component,
  ElementRef,
  QueryList,
  HostListener,
  Renderer2,
  ViewChild,
  ContentChildren,
  Output,
  EventEmitter,
  AfterContentInit,
  OnDestroy,
  Input,
} from '@angular/core';
import { SlideComponent } from '../slide/slide.component';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { defaultEasing } from '@core/animations';
import { MouseInput } from 'hammerjs'; // Don't Delete, Used for SSR

@Component({
  selector: 'ilx-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  animations: [
    trigger('animateSlide', [
      state('*', style({ transform: 'translateX({{translateTo}}px)' }), { params: { translateTo: 0 } }),
      state('false', style({ transform: 'translateX(0)' })),
      transition('* => *', [animate('600ms ' + defaultEasing)]),
    ]),
  ],
})
export class SliderComponent implements AfterContentInit, OnDestroy {
  @Output()
  public indexChange = new EventEmitter<number>();

  @Input()
  public itemsBefore = 0;

  @Input()
  public itemsAfter = 0;

  public animateDisabled = false;

  public currentIndex = 0;
  public slideCount = 1;

  public currentSlide = 0;
  public get totalSlides() {
    return this.slideCount - this.itemsAfter - this.itemsBefore;
  }

  public moveToSlide: number = null;
  public isAnimating = false;

  // private get lastSlide() {
  //   return this.slideItems.length - this.slidesPrView;
  // }

  private slideWidth = 0;
  private sliderViewWidth = 0;
  public get slidesPrView() {
    return Math.round(this.sliderViewWidth / this.slideWidth);
  }

  public lastPosition = 0;

  private resizeStream = new Subject();

  private subcriptions: Subscription[] = [];

  @ViewChild('sliderView', { static: true }) viewEl: ElementRef;
  @ViewChild('sliderWidth', { static: true }) viewWidthEl: ElementRef;
  @ViewChild('sliderPanel', { static: true }) panelEl: ElementRef;

  @ContentChildren(SlideComponent, { descendants: true }) slideItems: QueryList<SlideComponent>;
  constructor(private hostElement: ElementRef, private renderer: Renderer2) { }

  public ngAfterContentInit() {
    // Get with of first element, amuses all elements are equal width!
    this.slideWidth = this.slideItems.first.element.nativeElement.clientWidth;
    this.sliderViewWidth = this.viewWidthEl.nativeElement.clientWidth;
    this.slideCount = this.slideItems.length;

    this.subcriptions.push(
      this.resizeStream.pipe(debounceTime(250)).subscribe(() => {
        this.slideWidth = this.slideItems.first.element.nativeElement.clientWidth;
        this.sliderViewWidth = this.viewWidthEl.nativeElement.clientWidth;
        this.slideTo(this.currentIndex, false);
      })
    );

    this.slideTo(this.itemsBefore, false);
  }

  public ngOnDestroy() {
    this.subcriptions.forEach((n) => n.unsubscribe());
  }

  @HostListener('window:resize')
  public onWindowResize() {
    this.resizeStream.next();
  }

  public prev() {
    this.slideTo(this.currentIndex - 1);
  }

  public next() {
    this.slideTo(this.currentIndex + 1);
  }

  public swipePrev($event: TouchInput) {
    $event.srcEvent.stopPropagation();
    setTimeout(() => this.slideTo(this.currentIndex - 1), 0);
  }

  public swipeNext($event: TouchInput) {
    $event.srcEvent.stopPropagation();
    setTimeout(() => this.slideTo(this.currentIndex + 1), 0);
  }

  public panStart(_$event: MouseInput) {
    this.renderer.addClass(this.hostElement.nativeElement, 'is-panning');
  }

  public panMove($event: MouseInput) {
    this.renderer.setStyle(this.panelEl.nativeElement, 'transform', `translateX(${this.lastPosition + $event.deltaX}px)`);
  }

  public panEnd($event: MouseInput) {
    this.renderer.removeClass(this.hostElement.nativeElement, 'is-panning');

    this.animateDisabled = true;
    this.lastPosition = this.lastPosition + $event.deltaX;

    // Find out which slide to stick to;
    const slidesMoved =
      Math.abs($event.deltaX % this.slideWidth) > this.slideWidth / 2
        ? Math.floor($event.deltaX / this.slideWidth)
        : Math.ceil($event.deltaX / this.slideWidth);

    const slide = this.currentIndex + slidesMoved * -1;
    setTimeout(() => this.slideTo(slide), 0);
  }

  public panCancel(_$event: MouseInput) {
    this.renderer.removeClass(this.hostElement.nativeElement, 'is-panning');
    this.slideTo(this.currentIndex);
  }

  public slideTo(slide: number, animations = true) {

    if (this.isAnimating) {
      return;
    }

    this.animateDisabled = !animations;

    this.lastPosition = -Math.floor(slide * this.slideWidth);
    this.currentSlide = slide - this.itemsBefore;
    this.currentIndex = slide;

    if (this.currentIndex < this.itemsBefore) {
      this.currentSlide = this.totalSlides;
      this.moveToSlide = this.itemsBefore + this.currentIndex;
      this.indexChange.next(this.moveToSlide);
    } else if (this.currentIndex >= this.slideCount - this.itemsAfter) {
      this.currentSlide = 0;
      this.moveToSlide = this.currentIndex - this.itemsAfter;
      this.indexChange.next(this.moveToSlide);
    } else if (this.currentSlide === this.totalSlides) {
      this.currentSlide = 0;
      this.indexChange.next(slide);
    } else {
      this.indexChange.next(slide);
    }
  }

  public animateSlideStart(e: AnimationEvent) {
    this.isAnimating = true;
  }

  public animateSlideDone(e: AnimationEvent) {
    this.isAnimating = false;

    if (this.moveToSlide !== null) {
      setTimeout(() => {
        this.slideTo(this.moveToSlide, false);
        this.moveToSlide = null;
      }, 0);
    }
  }
}
