import { Component } from '@angular/core';
import { CueContent, CueContentAware, CueContentType, CueElement } from '@cue-core';
import { OpacityLayerService, OpacityLayer } from '@layout/opacity-layer/opacity-layer.service';
import { BreakpointService, Breakpoint } from '@services/breakpoint.service';

@CueContentType({ types: ['page', 'article', 'story'] })
@Component({
  selector: 'sdx-page-standard',
  templateUrl: './page-standard.component.html',
  styleUrls: ['./page-standard.component.scss'],
})
export class PageStandardComponent implements CueContentAware {

  public cueContent: CueContent;

  public trackByFnc = (i: number, n: CueElement) => n.id;

  constructor() { }
}
