import { Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { CueRouter } from './cue-router';

@Component({
  selector: 'cue-router-outlet',
  template: '',
})
export class CueRouterOutletComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<boolean>();

  constructor(private cueRouter: CueRouter, public viewContainerRef: ViewContainerRef) {}

  public ngOnInit() {
    this.cueRouter.createAndRenderComponent(this.viewContainerRef, this.cueRouter.routeSnapshot());
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
