import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgModuleRef } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Resolve, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CueContent, CueContentService } from '../cue-core';

import { CueRouter } from './cue-router';
import { wrapIntoObservable } from './cue-router-utils';

@Injectable()
export class CanActivateCueRoute implements CanActivate, CanActivateChild {
  constructor(private cueContentService: CueContentService, private cueRouter: CueRouter) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.getContent(route.url);
  }

  private getContent(urlSegments: UrlSegment[]) {
    return this.cueContentService.getContent(urlSegments).pipe(
      map((x) => this.cueRouter.setRoute(x)),
      catchError((response: HttpErrorResponse) => {
        console.error('Cannot load cue content. URL: "' + urlSegments.join('/') + '"', response.error);
        this.cueRouter.setFallbackRoute(response.status);
        return of(true);
      })
    );
  }

  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}

@Injectable()
export class DetectCueRouteResolver implements Resolve<any> {
  constructor(private cueContentService: CueContentService, private cueRouter: CueRouter) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const routeSnapshot = this.cueRouter.routeSnapshot();

    if (routeSnapshot) {
      return of(true);
    }

    return of(false);
  }
}

@Injectable()
export class CueDataResolver implements Resolve<any> {
  constructor(private ngModule: NgModuleRef<any>, private cueRouter: CueRouter) { }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const contentType = this.cueRouter.routeSnapshot().contentType;

    if (!contentType || !contentType.resolveTypes) {
      return null;
    }

    const resolveDataTypes = contentType.resolveTypes;

    return forkJoin(
      Object.keys(resolveDataTypes).map((key) => {
        const obj = this.ngModule.injector.get<Resolve<any>>(resolveDataTypes[key]);

        return wrapIntoObservable(obj.resolve(route, state));
      })
    ).pipe(
      map((x) => {
        const data = {
          contentType: {
            type: contentType.type,
          },
        };

        let i = 0;

        // tslint:disable-next-line: forin
        for (const key in resolveDataTypes) {
          data[key] = x[i];
          i++;
        }

        return data;
      })
    );
  }
}
