import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { CueContent } from '../cue/cue-core/cue-content';
import { RESPONSE, REQUEST } from '@nguniversal/express-engine/tokens';
import { Request, Response } from 'express';
@Injectable({
  providedIn: 'root',
})
@Injectable({ providedIn: 'root' })
export class MetaService {
  private canonicalEl: HTMLLinkElement = null;

  constructor(@Inject(DOCUMENT) private doc,
    @Optional() @Inject(REQUEST) private request: Request,
    @Optional() @Inject(RESPONSE) private response: Response,
    @Inject(PLATFORM_ID) private platformId: any,
    private title: Title,
    private meta: Meta
  ) {
    this.canonicalEl = this.createCanonicalElement();
    this.doc.head.appendChild(this.canonicalEl);
  }

  public setMeta(content: CueContent) {
    // Remove tag ifsset
    const metaTitle = content.metaData.meta_title || content.title;
    const metaDescription = content.metaData.meta_description || '';
    const metaRobots = content.metaData.meta_robots || 'index, follow';
    const metaAuthor = content.metaData.meta_author || 'StiboDX';

    // // set meta image
    const ogImageUrl = content.metaData.og_image?.alternates?.href_full || 'https://stibodx.com/assets/stibodx-share-logo.png';

    this.title.setTitle(metaTitle);

    this.meta.updateTag({ name: 'robots', content: metaRobots });
    this.meta.updateTag({ name: 'description', content: metaDescription });
    this.meta.updateTag({ name: 'author', content: metaAuthor });

    this.meta.updateTag({ property: 'og:title', content: metaTitle });
    this.meta.updateTag({ property: 'og:description', content: metaDescription });

    if (!!content.metaData.locale) {
      this.doc.documentElement.lang = content.metaData.locale.includes('_')
        ? content.metaData.locale.split('_')[0]
        : content.metaData.locale;
    }

    this.meta.updateTag({ property: 'og:image', content: ogImageUrl });

    if (!!content.metaData.canonical_url) {
      this.canonicalEl.setAttribute('href', content.metaData.canonical_url);
    }
  }

  public setFallbackMeta() {
    this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' });
    if (isPlatformServer(this.platformId)) {
      this.response.status(500);
    }
  }

  public set404Meta() {
    this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' });
    if (isPlatformServer(this.platformId)) {
      this.response.status(404);
    }
  }


  private createCanonicalElement() {
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    return link;
  }
}
