<div class="container-01" (sdxInView)="inView = true" [@staggerIn]="inView">
  <div class="row v-gutters align-items-stretch">

    <div class="col-12 landing-col-image stagger-in">
        <picture class="lazyload-picture ratio-450-640" [ngClass]="{'is-ready': cueElement.image.isLoaded}">
          <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" class="lazyload-img" [alt]="cueElement.image.alt_text" (sdxInView)="inView = $event" [sdxLazyload]="cueElement.image.imageSources" [lazyloadIf]="inView" (lazyloaded)="cueElement.image.isLoaded = true;" />
        </picture>
    </div>

    <div class="col-12 landing-col-center stagger-in">
      <sdx-cue-content-portal *ngFor="let e of cueElement.column.elements; trackBy: trackByFnc" [content]="e"></sdx-cue-content-portal>
    </div>

    <div class="col-12 landing-col-html stagger-in">
      <sdx-cue-content-portal [content]="cueElement.html"></sdx-cue-content-portal>
    </div>

  </div>
</div>
