import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchFormComponent } from './search-form/search-form.component';
import { SearchResultsComponent } from './search-results/search-results.component';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { SearchPageComponent } from './search-page/search-page.component';

@NgModule({
  declarations: [SearchFormComponent, SearchResultsComponent, SearchPageComponent],
  imports: [CommonModule, RouterModule, HttpClientModule, FormsModule, ReactiveFormsModule, SharedModule],
  exports: [SearchFormComponent, SearchResultsComponent, SearchPageComponent]
})
export class SearchModule { }
