import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUE_ELEMENT_TYPES, ComponentsModule } from '@components/components.module';
import { CUE_ELEMENT_MODELS } from '@cue-models';
import { CueCoreModule } from '@cue-core';
import { CueRouterModule } from '@cue-router';
import { environment } from '@env/environment';
import { MainLayoutComponent } from '@layout/main-layout/main-layout.component';
import { PageErrorComponent } from '@pages/page-error/page-error.component';
import { CUE_PAGE_TYPES, PagesModule } from '@pages/pages.module';
import { AppComponent } from './app.component';
import { CachingInterctor } from '@core/caching.interceptor';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { SearchPageComponent } from 'src/app/search/search-page/search-page.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    RouterModule.forRoot([{ path: 'search', component: SearchPageComponent }]),
    CueCoreModule.forRoot({
      contentTypes: [...CUE_PAGE_TYPES, ...CUE_ELEMENT_TYPES],
      contentModels: [...CUE_ELEMENT_MODELS],
      host: environment.apiUrl,
    }),
    CueRouterModule.forRoot(MainLayoutComponent, {
      fallback: PageErrorComponent,
      options: {
        scrollPositionRestoration: 'enabled',
        enableTracing: false,
      },
    }),
    PagesModule,
    LayoutModule,
    ComponentsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CachingInterctor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
