import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sdx-cta-secondary',
  templateUrl: './cta-secondary.component.html',
  styleUrls: ['./cta-secondary.component.scss']
})
export class CtaSecondaryComponent {

  @Input()
  public theme: 'default' | 'green' = 'default';

  @Input()
  public text = '';

  @Input()
  public href = '';

  @Input()
  public target: string;

  @Input()
  public label: string;

  @Input()
  public rel: string;

  @Input()
  public isHovered = false;
}
