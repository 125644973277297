import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LazyloadDirective } from './lazyload/lazyload.directive';
import { InViewDirective } from './in-view/in-view.directive';
import { ClickOutsideDirective } from './click-outside/click-outside.directive';
import { TranslatePipe } from './translate/translate.pipe';
import { TrustUrlPipe } from './trust-url/trust-url';
import { CounterComponent } from './counter/counter.component';
import { FocusOnDirective } from './focus-on/focus-on.directive';
import { IsExternalPipe } from './is-external/is-external.pipe';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { OnlyHrefPipe } from './navigation/only-href.pipe';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
  declarations: [
    LazyloadDirective,
    InViewDirective,
    FocusOnDirective,
    ClickOutsideDirective,
    TranslatePipe,
    TrustUrlPipe,
    CounterComponent,
    BreadcrumbsComponent,
    IsExternalPipe,
    OnlyHrefPipe,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  exports: [
    LazyloadDirective,
    InViewDirective,
    FocusOnDirective,
    ClickOutsideDirective,
    TranslatePipe,
    TrustUrlPipe,
    CounterComponent,
    BreadcrumbsComponent,
    IsExternalPipe,
    OnlyHrefPipe,
    LoaderComponent
  ],
})
export class SharedModule { }
