import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CueContent, CueElement, ICueNavChild } from '@cue-core';

@Component({
  selector: 'sdx-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FooterComponent implements OnInit {

  @Input()
  public cueContent: CueContent;

  public disclaimer: string;

  public activeLevels: number[] = [];

  ngOnInit(): void {
    this.disclaimer = this.cueContent?.navigation.disclaimer[0]?.item.text;

  }

  public toggleLevel(index: number) {
    const i = this.activeLevels.indexOf(index);
    if (i === -1) {
      this.activeLevels.push(index);
    } else {
      this.activeLevels.splice(i, 1);
    }
  }

  public isSocial(url: string) {
    return ['youtube', 'twitter', 'facebook', 'linkedin'].find(n => url && url.toLowerCase().includes(n));
  }

  public isSocialGroup(i: number) {
    return i === this.cueContent.navigation.footer.length - 1;
  }

  public trackByIndex = (i: number, n: ICueNavChild) => i;

}
