import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isExternal',
  pure: true,
})
export class IsExternalPipe implements PipeTransform {
  transform(value: string): any {
    if (!value) {
      return true;
    }
    return value.includes('https://') || value.includes('http://') || value.includes('www.');
  }
}
