import { Component, HostBinding } from '@angular/core';
import { staggerIn } from '@core/animations';
import { CueContentType, CueElement, CueElementAware } from '@cue-core';
import { BackgroundImageModuleModel } from '@cue-models';

@CueContentType({ types: ['background_image_module'] })
@Component({
  selector: 'sdx-background-image',
  templateUrl: './background-image.component.html',
  styleUrls: ['./background-image.component.scss'],
  animations: [staggerIn]
})
export class BackgroundImageComponent implements CueElementAware {

  public cueElement: BackgroundImageModuleModel;

  @HostBinding('class')
  get hostClasses(): string[] {

    const classList = [
      'spacing-top-' + this.cueElement.spacing_top,
      'spacing-bottom-' + this.cueElement.spacing_bottom,
    ];

    return classList;
  }

  public trackByFnc = (i: number, n: CueElement) => n.id;

}
