import { Component, OnInit } from '@angular/core';
import { CueContentType, CueElement, CueElementAware } from '@cue-core';
import { LogoImageModel, LogoRibbonModuleModel } from '@core/content-models';
import { BaseComponent } from '@core/base.component';
import { AnimationStateService } from '@services/animation-state.service';
import { staggerIn } from '@core/animations';

@CueContentType({ types: ['logo_ribbon_module'] })
@Component({
  selector: 'sdx-highlight-logo',
  templateUrl: './highlight-logo.component.html',
  styleUrls: ['./highlight-logo.component.scss'],
  animations: [staggerIn]
})
export class HighlightLogoComponent extends BaseComponent implements OnInit, CueElementAware {

  public cueElement: LogoRibbonModuleModel;

  public inView = false;

  public groups: LogoImageModel[][];

  constructor(private animationStateService: AnimationStateService) {
    super();
  }

  ngOnInit() {
    this.animationStateService.disabled
      .pipe(this.takeUntilDestroyed())
      .subscribe((disabled) => this.disableAnimations = disabled);

    this.groups = this.groupByCount(this.cueElement.elements, 3);
  }


  private groupByCount(items: LogoImageModel[], x: number) {
    const groups: LogoImageModel[][] = [];
    let group: LogoImageModel[] = [];
    items.forEach((n, i) => {
      group.push(n);
      if ((i + 1) % x === 0) {
        groups.push(group);
        group = [];
      }
    });

    if (group.length > 0) {
      groups.push(group);
    }
    return groups;
  }

  public trackByFnc = (i: number, n: CueElement) => n.id;
}
