<div class="slider-view" #sliderView>
  <div class="slider-panel"
    #sliderPanel
    [@.disabled]="animateDisabled"
    [@animateSlide]="{value: lastPosition, params: {translateTo: lastPosition}}"
    (@animateSlide.start)="animateSlideStart($event)"
    (@animateSlide.done)="animateSlideDone($event)"
    (swipeleft)="swipeNext($event)"
    (swiperight)="swipePrev($event)"
    (panstart)="panStart($event)"
    (panmove)="panMove($event)"
    (panleft)="panMove($event)"
    (panright)="panMove($event)"
    (panend)="panEnd($event)"
    (pancancel)="panCancel($event)">
    <div class="slider-view-width" #sliderWidth></div>
    <ng-content></ng-content>
  </div>
</div>
<!-- index: {{currentIndex}} / slide: {{currentSlide}} -->

<div class="indicator-dots"><sdx-carousel-indicator [current]="currentSlide" [amount]="totalSlides" (changeCurrent)="slideTo($event + itemsBefore)"></sdx-carousel-indicator></div>

<div class="indicator-numbers"><sdx-slider-indicator  [current]="currentSlide" [amount]="totalSlides" (previous)="prev()" (next)="next()"></sdx-slider-indicator></div>
