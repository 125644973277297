import { Component, HostBinding, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { staggerIn } from '@core/animations';
import { BaseComponent } from '@core/base.component';
import { CueContentType, CueElement, CueElementAware } from '@cue-core';
import { FaqModule, IFaqChild } from '@cue-models';
import { AnimationStateService } from '@services/animation-state.service';

@CueContentType({ types: ['faq'] })
@Component({
  selector: 'sdx-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  animations: [staggerIn]
})
export class FaqComponent extends BaseComponent implements OnInit, CueElementAware {
  public cueElement: FaqModule;

  @HostBinding('class')
  get hostClasses(): string[] {
    return ['spacing-top-' + this.cueElement.spacing_top, 'spacing-bottom-' + this.cueElement.spacing_bottom];
  }

  public inView = false;

  public schema: SafeHtml;
  constructor(private animationStateService: AnimationStateService, private sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit() {

    this.schema = this.createShemaMarkup(this.cueElement.children);
    this.animationStateService.disabled
      .pipe(this.takeUntilDestroyed())
      .subscribe((disabled) => this.disableAnimations = disabled);
  }

  createShemaMarkup(faqs: IFaqChild[]) {
    const json = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: [faqs.map((n) => ({ '@type': 'Question', name: n.question, acceptedAnswer: { '@type': 'Answer', text: n.answer } }))]
    };
    const str = `<script type="application/ld+json">${JSON.stringify(json)}'</script>`;
    return this.sanitizer.bypassSecurityTrustHtml(str);
  }

  public trackByFnc = (i: number, n: CueElement) => n.id;

}
