import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sdx-cta-back',
  templateUrl: './cta-back.component.html',
  styleUrls: ['./cta-back.component.scss']
})
export class CtaBackComponent {

  @Input()
  public theme: 'default' = 'default';

  @Input()
  public text = 'Back';

  @Input()
  public href = '';

  @Input()
  public target: string;

  @Input()
  public label: string;

  @Input()
  public rel: string;

}
