import { HostBinding } from '@angular/core';
import { Component } from '@angular/core';
import { CueContentType, CueElementAware } from '@cue-core';
import { ArticleCallOutModel } from '@core/content-models';

@CueContentType({ types: ['article_call_out'] })
@Component({
  selector: 'sdx-call-out',
  templateUrl: './call-out.component.html',
  styleUrls: ['./call-out.component.scss'],
})
export class CallOutComponent implements CueElementAware {
  cueElement: ArticleCallOutModel;

  @HostBinding('class')
  get hostClasses(): string[] {
    return ['spacing-top-' + this.cueElement.spacing_top, 'spacing-bottom-' + this.cueElement.spacing_bottom];
  }
}
