import { Component, Input, OnInit } from '@angular/core';
import { staggerIn } from '@core/animations';
import { CueElement } from '@cue-core';
import { SolutionCardModel } from '@cue-models';

@Component({
  selector: 'sdx-solution-card',
  templateUrl: './solution-card.component.html',
  styleUrls: ['./solution-card.component.scss'],
  animations: [staggerIn]
})
export class SolutionCardComponent {

  @Input()
  theme: string;

  @Input()
  ctaTheme: string;

  @Input()
  card: SolutionCardModel;

  @Input()
  public inView = false;

  public isHovered = false;

  public trackByFnc = (i: number, n: CueElement) => n.id;

}
