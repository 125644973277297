import { Component, Input } from '@angular/core';

@Component({
  selector: 'sdx-cta-tertiary',
  templateUrl: './cta-tertiary.component.html',
  styleUrls: ['./cta-tertiary.component.scss'],
})
export class CtaTertiaryComponent {
  @Input()
  public theme: 'default' | 'green' | 'light' = 'default';

  @Input()
  public text = '';

  @Input()
  public href = '';

  @Input()
  public target: string;

  @Input()
  public label: string;

  @Input()
  public rel: string;

  @Input()
  public isHovered = false;
}
