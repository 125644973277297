<!--Side By Side Module with Image Left -->
<section class="container-03 mb-5" (sdxInView)="inView = true">
  <div class="side-by-side layout-50-50" [ngClass]="[cueElement.placement?.toLocaleLowerCase() === 'text_right' ? 'image-left' : 'image-right']">

    <picture *ngIf="cueElement.image" class="side-by-side-image lazyload-picture {{imageTheme}}" [ngClass]="{'is-ready': cueElement.image.isLoaded}">
      <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" class="lazyload-img object-fit-contain" [alt]="cueElement.image.alt_text" [sdxLazyload]="cueElement.imageSources" [lazyloadIf]="inView" (lazyloaded)="cueElement.image.isLoaded = true;" />
    </picture>

    <div class="side-by-side-text rte" [ngClass]="textTheme">
      <sdx-cue-content-portal class="d-block" *ngFor="let e of cueElement.elements; trackBy: trackByFnc" [content]="e"></sdx-cue-content-portal>
    </div>

  </div>
</section>
