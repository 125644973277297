<div class="slider-indicator" [ngClass]="'indicator-theme-'+theme">
  <svg class="slider-previous" width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="previous.next(current)">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M3.45629 6.81649L8.31047 11.6732H6.4638L0.980469 6.16319L1.22642 5.92694L1.64035 5.51275V5.50982H1.64328L6.46365 0.686523H8.31031L3.48953 5.50982H14.6937V6.81649H3.45629Z"
      fill="#141a5b" />
  </svg>
  <span class="slider-space">{{getValue(current + 1)}}</span>
   <span class="slider-diveder">/</span>
  <span class="slider-space">{{getValue(amount)}}</span>
  <svg class="slider-next" width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="next.next(current)">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M11.5437 5.18351L6.68953 0.326807L8.5362 0.326807L14.0195 5.83681L13.7736 6.07306L13.3597 6.48725L13.3597 6.49018L13.3567 6.49018L8.53635 11.3135L6.68969 11.3135L11.5105 6.49018L0.306316 6.49018L0.306316 5.18351L11.5437 5.18351Z"
      fill="#141a5b" />
  </svg>
</div>
