import { animate, group, query, stagger, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { defaultEasing } from '@core/animations';
import { Router, Event, NavigationEnd } from '@angular/router';
import { AnimationStateService } from '@services/animation-state.service';
import { AnimationEvent } from '@angular/animations';

@Component({
  selector: 'sdx-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  animations: [
    trigger('pageAnimations', [
      transition('void => *', [query('enter', style({ position: 'fixed', width: '100%' }), { optional: true })]),
      transition('none => firstView', [
        query('.overlay', style({ position: 'absolute', width: '0%', height: '100%', top: 0, left: 0 }), { optional: true }),
        query(':enter', style({ opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%' }), { optional: true }),
        group([
          query('.overlay', [style({ background: '#141a5b', width: '0%' }),
          animate('0.5s ' + defaultEasing, style({ width: '100%' })),
          animate('0.1s', style({ right: 0, left: 'unset' })),
          animate('0.5s ' + defaultEasing, style({ width: '0%' })),
          ], { optional: true, }),
          query(':leave', stagger(500, [style({ opacity: 0 })]), { optional: true, }),
          query(':enter', stagger(500, [style({ opacity: 1 })]), { optional: true, }),
        ]),
      ]),
      transition('none => firstLevel', [
        query('.overlay', style({ position: 'absolute', width: '0%', height: '100%', top: 0, left: 0 }), { optional: true }),
        query(':enter', style({ opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%' }), { optional: true }),
        group([
          query('.overlay', [style({ background: '#141a5b', width: '0%' }),
          animate('0.5s ' + defaultEasing, style({ width: '100%' })),
          animate('0.1s', style({ right: 0, left: 'unset' })),
          animate('0.5s ' + defaultEasing, style({ width: '0%' })),
          ], { optional: true, }),
          query(':leave', stagger(500, [style({ opacity: 0 })]), { optional: true, }),
          query(':enter', stagger(500, [style({ opacity: 1 })]), { optional: true, }),
        ]),
      ]),
      transition('none => secondLevel', [
        query(':enter', [style({ opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%' })], { optional: true }),
        query(':leave', [style({ opacity: 1 })], { optional: true }),
        query('.main-content', [style({ opacity: 1 }), animate('0.5s ' + defaultEasing, style({ opacity: 0 }))]),
      ]),

    ]),
  ],
})

export class MainLayoutComponent implements OnInit, AfterViewInit {

  public animationState = 'none';

  constructor(private router: Router, private animationStateService: AnimationStateService) { }

  ngOnInit(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const urlSegments = event.url.split('/');
        if (urlSegments.length === 2) {
          this.animationState = 'firstLevel';
        } else {
          this.animationState = 'secondLevel';
        }
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => this.animationStateService.disable(false), 300);
  }

  public animationsStart(event: AnimationEvent) {
    if (event.toState === 'firstLevel' || event.toState === 'secondLevel') {
      this.animationStateService.disable(true);
      return;
    }
  }

  public animationsDone(event: AnimationEvent) {
    if (event.toState !== 'none') {
      this.animationState = 'none';
      this.animationStateService.disable(false);
    }
  }
}
