import { ComponentFactoryResolver, ComponentRef, Directive, Injector, Input, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { CURRENT_CUE_CONTENT, CueElement, CueElementAware } from './cue-content';
import { CueContentTypeProvider } from './cue-content-type-provider';

@Directive({
  selector: 'sdx-cue-content-portal',
})
export class CueContentPortalDirective implements OnDestroy, OnInit {

  @Input() public content: CueElement;

  constructor(public vcr: ViewContainerRef, private cfr: ComponentFactoryResolver, private contentTypeProvider: CueContentTypeProvider) {}

  private _componentRef: ComponentRef<CueElementAware>;

  public ngOnInit() {
    if (!this.contentTypeProvider.has(this.content.type)) {
      console.error(this.content.type + ' is not referenced as an element.', this.content);
      return;
    }

    const contentTypeDef = this.contentTypeProvider.get(this.content.type);

    const componentFactory = this.cfr.resolveComponentFactory(contentTypeDef.component);

    this.vcr.clear();

    const injector = Injector.create({
      providers: [
        {
          provide: CURRENT_CUE_CONTENT,
          useFactory: () => this.content,
          deps: [],
        },
      ],
      parent: this.vcr.injector,
    });

    this._componentRef = this.vcr.createComponent(componentFactory, 0, injector);
    this._componentRef.instance.cueElement = this.content;
  }

  public ngOnDestroy(): void {
    if (this._componentRef) {
      this._componentRef.destroy();
    }
  }
}
