import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'ilx-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss'],
})
export class SlideComponent {
  constructor(public element: ElementRef) {  }
}
