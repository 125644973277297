import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CUE_HEADLESS_HOST } from './cue-content';
import { CUE_CONTENT_TYPES } from './cue-content-type';
import { CUE_CONTENT_MODELS } from './cue-content-model';
import { CueContentPortalDirective } from './cue-content-portal';

export interface ICueCoreConfig {
  contentTypes: Type<any>[]; contentModels: Type<any>[]; host?: string;
}

@NgModule({
  declarations: [CueContentPortalDirective],
  exports: [CueContentPortalDirective],
})
export class CueCoreModule {
  static forRoot(config: ICueCoreConfig): ModuleWithProviders<CueCoreModule> {
    return {
      ngModule: CueCoreModule,
      providers: [
        !!config.host ? { provide: CUE_HEADLESS_HOST, useValue: config.host } : [],
        { provide: CUE_CONTENT_TYPES, multi: true, useValue: config.contentTypes },
        { provide: CUE_CONTENT_MODELS, multi: true, useValue: config.contentModels },
      ],
    };
  }
}
