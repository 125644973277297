<footer class="footer-container">
  <div class="footer-row">
    <div *ngFor="let group of cueContent.navigation.footer; trackBy: trackByIndex; let i = index">
      <div class="footer-group-header" (click)="toggleLevel(i)" [ngClass]="{'is-active': activeLevels.includes(i), 'is-social': isSocialGroup(i) }">
        <h5 class="footer-headline"><b>{{group.item.text}}</b></h5>
      </div>
      <div class="footer-group-content" [ngClass]="{'is-active': activeLevels.includes(i) || isSocialGroup(i) }">
        <ul class="footer-list">
          <li class="footer-list-item" [ngClass]="{'is-social': !!isSocial(n.item.href)}" *ngFor="let n of group.children; trackBy: trackByIndex">
            <a *ngIf="!!n.item.href" [href]="n.item.href" class="footer-link" rel="noopener" [ngClass]="!!isSocial(n.item.href) ? 'icon-'+isSocial(n.item.href) : ''" [attr.title]="n.item.text" [attr.aria-label]="n.item.text"><span class="footer-link-text">{{n.item.text}}</span></a>
            <span *ngIf="!n.item.href">{{n.item.text}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="disclaimer" class="footer-disclaimer">{{disclaimer}}</div>
</footer>
