<section class="container-01">
  <div class="container-03 article-header-container">
    <sdx-cta-chip *ngIf="cueElement.label" [text]="cueElement.label"></sdx-cta-chip>
    <h1 class="mt-3 h3"><b>{{cueElement.headline}}</b></h1>
    <p>{{cueElement.publication_date | date:'MMMM dd, yyyy'}} – {{cueElement.timeToRead}} {{ "global_minread" | translate | async }}</p>

    <ul class="article-header-list" *ngIf="cueElement.twitter_share =='true' || cueElement.linkedin_share =='true' || cueElement.facebook_share =='true' ">
      <li class="article-header-item"><b>Share on</b></li>
      <li *ngIf="cueElement.twitter_share === 'true'" class="article-header-item"><a target="_blank" rel="noopener" [attr.href]="'https://www.twitter.com/share?url='+ url">Twitter</a></li>
      <li *ngIf="cueElement.linkedin_share === 'true'" class="article-header-item"><a target="_blank" rel="noopener" [attr.href]="'https://www.linkedin.com/sharing/share-offsite/?url='+ url">LinkedIn</a></li>
      <li *ngIf="cueElement.facebook_share === 'true'" class="article-header-item"><a target="_blank" rel="noopener" [attr.href]="'https://www.facebook.com/share.php?u='+ url">Facebook</a></li>
    </ul>
  </div>
</section>
