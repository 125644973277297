import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { debounceTime, filter, distinctUntilChanged } from 'rxjs/operators';
import { ISearchResults, SearchService } from '../search.service';

@Component({
  selector: 'sdx-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit, OnChanges {
  public form: FormGroup;

  @Output()
  public isLoading = new EventEmitter<boolean>();

  @Output()
  public results = new EventEmitter<ISearchResults>();

  @Input()
  query: string;

  @Output()
  public queryChange = new EventEmitter<string>();

  constructor(private formBuilder: FormBuilder, private searchService: SearchService) {
    this.form = this.formBuilder.group({
      query: [undefined, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.form
      .get('query')
      .valueChanges.pipe(debounceTime(450))
      .pipe(filter((n) => this.form.get('query').valid && !!n))
      .pipe(distinctUntilChanged())
      .subscribe((value) => this.search(value));
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!!changes.query && changes.query.currentValue !== changes.query.previousValue) {
      const control = this.form.get('query');
      if (control.value !== changes.query.currentValue) {
        control.patchValue(changes.query.currentValue);
        this.search(changes.query.currentValue)
      }
    }
  }

  public onSubmit() {
    return;
  }

  private search(value: string) {
    this.isLoading.next(true);
    this.queryChange.next(value);
    this.searchService.query(value).subscribe((result) => {
      this.isLoading.next(false);
      this.results.next(result);
    });
  }

  clear() {
    this.isLoading.next(false);
    this.results.next(undefined);
    this.queryChange.next(null);
    this.form.patchValue({ query: undefined });
  }

}
