import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class CachingInterctor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('api') > -1) {
      request = request.clone({
        headers: request.headers
          .set('Pragma', 'no-cache')
          .set('Cache-Control', 'no-cache')
      });
    }
    return next.handle(request);
  }
}
