<div class="feature-card" [ngClass]="[theme, card.image ? 'with-image' : 'without-image', card.link ? 'block-link' : '']" (sdxInView)="inView = true">
  <picture class="feature-card-picture lazyload-picture ratio-16-9" *ngIf="card.imageSources" [ngClass]="{'is-ready': card.image.isLoaded}">
    <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" class="lazyload-img" [ngClass]="{'add-padding': imagePadding}" [alt]="card.image.alt_text" [sdxLazyload]="card.imageSources" [lazyloadIf]="inView" (lazyloaded)="card.image.isLoaded = true;" />
  </picture>

  <div class="feature-card-content rte" *ngIf="!!card.headline || !!card.paragraph">
    <h5 class="card-title" *ngIf="!!card.headline">{{card.headline}}</h5>
    <p class="card-paragraph" *ngIf="!!card.paragraph" [innerHTML]="card.paragraph"></p>
  </div>

  <div class="feature-card-footer" *ngIf="card.link">
    <sdx-cta-tertiary [theme]="ctaTheme" [text]="card.link.text" [href]="card.link.link" [isHovered]="isHovered"></sdx-cta-tertiary>
  </div>

  <a *ngIf="card.link && !(card.link.link | isExternal)" class="block-link-overlay" [routerLink]="card.link.link" [title]="card.link.text" [attr.target]="card.link.target" [attr.rel]="card.link.rel" (mouseover)="isHovered = true" (mouseout)="isHovered = false"><span class="sr-only">{{card.link.text}}</span></a>
  <a *ngIf="card.link && (card.link.link | isExternal)" class="block-link-overlay" [href]="card.link.link" [title]="card.link.text" [attr.target]="card.link.target" [attr.rel]="card.link.rel" (mouseover)="isHovered = true" (mouseout)="isHovered = false"><span class="sr-only">{{card.link.text}}</span></a>
</div>
