import { InjectionToken, Type } from '@angular/core';
import 'reflect-metadata';

export interface CueContentModel {
  types: string[];
}

export function CueContentModel(contentModel: CueContentModel) {
  return <T extends new (...args: any[]) => {}>(constructor: T) => {
    Reflect.defineMetadata('cueContentModel', contentModel, constructor);
  };
}

export const CUE_CONTENT_MODELS = new InjectionToken<Type<any>[][]>('CUE_CONTENT_MODELS');
