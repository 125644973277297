<div *ngIf="!!href && !(href | isExternal)">
  <a class="cta-tertiary" [ngClass]="['cta-theme-' + theme, isHovered ? 'is-hovered' : '']" [routerLink]="[href]" [attr.title]="label" [attr.rel]="rel" [attr.target]="target">{{text}}
    <svg class="arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.237 6.85682L6.38289 2.00015L8.22956 2.00015L13.7129 7.51015L13.463 7.75022L13.0529 8.16054L13.0529 8.16348L13.05 8.16348L8.22956 12.9868L6.38289 12.9868L11.2037 8.16348L-0.000442083 8.16348L-0.000441969 6.85682L11.237 6.85682Z" />
    </svg>
  </a>
</div>
<div *ngIf="!!href && href | isExternal">
  <a class="cta-tertiary" [ngClass]="['cta-theme-' + theme, isHovered ? 'is-hovered' : '']" [attr.href]="href" [attr.title]="label" [attr.rel]="rel" [attr.target]="target">{{text}}
    <svg class="arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.237 6.85682L6.38289 2.00015L8.22956 2.00015L13.7129 7.51015L13.463 7.75022L13.0529 8.16054L13.0529 8.16348L13.05 8.16348L8.22956 12.9868L6.38289 12.9868L11.2037 8.16348L-0.000442083 8.16348L-0.000441969 6.85682L11.237 6.85682Z" />
    </svg>
  </a>
</div>
