<div class="navigation-container" clickOutside [clickOutsideIf]="activeItem !== null" (clickOutside)="activeItem = null" *ngIf="isDesktop || !isActive">
  <div class="p-horizontal">

    <div class="row no-gutters">
      <div class="col-lg-2 nav-height mobile-header">
        <a [routerLink]="'/'" title="StiboDX"><img class="nav-logo" width="130" height="26" alt="StiboDX Logo" src="../../../assets/stibodx-apng-1-loop.png" /></a>

        <div class="d-flex justify-content-center align-item-center">
          <div class="nav-search mr-3" [routerLink]="['/search']"></div>

          <div class="nav-toggle-wrapper" [ngClass]="{'is-active': isActive}">
            <div class="nav-toggle" [ngClass]="{'is-active': isActive}" (click)="toggleNav()"><span></span><span></span><span></span><span></span></div>
          </div>
        </div>

      </div>

      <div class="col-lg-8 order-1" (mouseleave)="onMouseLeave()">
        <nav role="navigation" class="nav" *ngIf="primaryItems.length > 0">

          <div class="main-nav-content nav-height">
            <ul #mainNav class="nav-list-level-0">
              <li *ngFor="let group of primaryItems; trackBy: trackByIndex" class="nav-list-item" (mouseover)="onMouseHover(group)"><a class="navigation-action" [routerLink]="group.item.href" [attr.href]="group.item.href" [attr.title]="group.item.label" [ngClass]="{'is-active': isUrlActive(group.item.href)}">{{group.item.text}}</a></li>
            </ul>
          </div>

          <div class="dropdown-content" *ngIf="activeItem" [style.padding-left.px]="navOffset">

            <ul class="nav-list-level-1">
              <li *ngFor="let n of activeItems; trackBy: trackByIndex" class="nav-list-item">
                <a *ngIf="!!n.item.href" class="navigation-action" [routerLink]="[n.item.href]" [attr.href]="n.item.href" [attr.title]="n.item.label" [ngClass]="{'is-active': isUrlActive(n.item.href)}">{{n.item.text}}</a>
                <h5 style="margin-bottom: 0;" *ngIf="!n.item.href">{{n.item.text}}</h5>
              </li>
            </ul>

          </div>

        </nav>
      </div>
      <div class="col-lg-2 order-0 order-lg-1 nav-height">
        <sdx-cta-tertiary *ngIf="!!cta.item && cta.item.href" class="nav-cta" [text]="cta.item.text" [href]="cta.item.href"></sdx-cta-tertiary>
      </div>
    </div>
  </div>

</div>

<div class="nav-mobile" *ngIf="isActive">
  <div class="nav-mobile-inner">

    <div class="mobile-header is-active">
      <div class="nav-toggle-wrapper" [ngClass]="{'is-active': isActive}">
        <div class="nav-toggle" [ngClass]="{'is-active': isActive}" (click)="toggleNav()"><span></span><span></span><span></span><span></span></div>
      </div>
    </div>

    <sdx-cta-tertiary *ngIf="!!cta.item && cta.item.href" class="nav-mobile-cta" [theme]="'green'" [text]="cta.item.text" [href]="cta.item.href"></sdx-cta-tertiary>

    <div class="nav-mobile-content nav-height">
      <ul #mainNav class="nav-mobile-list-0">
        <li *ngFor="let n of primaryItems; trackBy: trackByIndex" class="nav-list-item">
          <a *ngIf="n.children === null || n.children.length === 0" class="navigation-action" [routerLink]="[n.item.href]" [attr.href]="n.item.href" [attr.title]="n.item.label" (click)="this.toggleNav()" [ngClass]="{'is-active': isUrlActive(n.item.href)}">{{n.item.text}}</a>
          <div *ngIf="n.children !== null && n.children.length > 0">
            <span class="nav-mobile-expandable" [ngClass]="{'is-active': n.isExpanded || (n.isExpanded == undefined && isUrlActive(n.item.href))}" (click)="n.isExpanded = !n.isExpanded">{{n.item.text}}</span>
            <ul class="nav-mobile-list-1" *ngIf=" n.isExpanded || (n.isExpanded == undefined && isUrlActive(n.item.href))">
              <li *ngFor="let m of n.children | onlyHref; trackBy: trackByIndex" class="nav-list-item">
                <a class="navigation-action" [routerLink]="[m.item.href]" [attr.href]="m.item.href" [attr.title]="m.item.label" [ngClass]="{'is-active': isUrlActive(m.item.href)}">{{m.item.text}}</a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>

    <nav class="add-nav">
      <div class="toolbar-link" *ngFor="let n of toolbarItems; trackBy: trackByIndex">
        <a class="navigation-action" [attr.href]="n.href" [attr.title]="n.label" [ngClass]="{'is-active': isUrlActive(n.href)}">{{n.text}}</a>
      </div>
    </nav>
  </div>
</div>
