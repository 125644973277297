import { Component, HostBinding, OnInit } from '@angular/core';
import { CueContentType, CueElement, CueElementAware } from '@cue-core';
import { SideBySideModuleModel } from '@core/content-models';

@CueContentType({ types: ['side_by_side_module'] })
@Component({
  selector: 'sdx-side-by-side',
  templateUrl: './side-by-side.component.html',
  styleUrls: ['./side-by-side.component.scss'],
})
export class SideBySideComponent implements CueElementAware, OnInit {

  cueElement: SideBySideModuleModel;

  public imageTheme = 'theme-default';
  public textTheme = 'theme-default';

  @HostBinding('class')
  get hostClasses(): string[] {
    return ['spacing-top-' + this.cueElement.spacing_top, 'spacing-bottom-' + this.cueElement.spacing_bottom];
  }

  public inView = false;

  ngOnInit() {
    if (this.cueElement.color === 'blue') {
      this.imageTheme = 'theme-dark';
      this.textTheme = 'theme-dark';
    }
    else if (this.cueElement.color === 'blue_grey') {
      if (this.cueElement.placement?.toLocaleLowerCase() === 'text_right') {
        this.imageTheme = 'theme-dark';
        this.textTheme = 'theme-default';
      } else {
        this.imageTheme = 'theme-default';
        this.textTheme = 'theme-dark';
      }
    }
    else if (this.cueElement.color === 'grey_blue') {
      if (this.cueElement.placement?.toLocaleLowerCase() === 'text_right') {
        this.imageTheme = 'theme-default';
        this.textTheme = 'theme-dark';
      } else {
        this.imageTheme = 'theme-dark';
        this.textTheme = 'theme-default';
      }
    }
  }

  public trackByFnc = (i: number, n: CueElement) => n.id;

}
