import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ArticleHeaderComponent } from './article-header/article-header.component';
import { BulletinComponent } from './bulletin/bulletin.component';
import { CallOutComponent } from './call-out/call-out.component';
import { ColumnComponent } from './column/column.component';
import { CTAsModule } from '../ctas/ctas.module';
import { CueCoreModule } from '../cue/cue-core/cue-core.module';
import { FeatureCardsComponent } from './feature-cards/feature-cards.component';
import { FilterComponent } from './filter/filter.component';
import { HammerModule } from '@angular/platform-browser';
import { HeadlineLargeComponent } from './headlines/headline-large.component';
import { HeadlineMediumComponent } from './headlines/headline-medium.component';
import { HeadlineMediumLargeComponent } from './headlines/headline-medium-large.component';
import { HeadlineSmallComponent } from './headlines/headline-small.component';
import { HeadlineXSComponent } from './headlines/headline-xs.component';
import { HeroComponent } from './hero/hero.component';
import { HighlightComponent } from './highlight/highlight.component';
import { HighlightLogoComponent } from './highlight-logo/highlight-logo.component';
import { HighlightSpotsComponent } from './highlight-spots/highlight-spots.component';
import { HighlightStatsComponent } from './highlight-stats/highlight-stats.component';
import { HtmlSnippetComponent } from './html-snippet/html-snippet.component';
import { ImageComponent } from './image/image.component';
import { LeadComponent } from './lead/lead.component';
import { LeadGeneratorComponent } from './lead-generator/lead-generator.component';
import { LinkComponent } from './link/link.component';
import { ParagraphLargeComponent } from './paragraphs/paragraph-large.component';
import { ParagraphMediumComponent } from './paragraphs/paragraph-medium.component';
import { ParagraphSmallComponent } from './paragraphs/paragraph-small.component';
import { ResourceCardsComponent } from './resource-cards/resource-cards.component';
import { SharedModule } from '../shared/shared.module';
import { SideBySideComponent } from './side-by-side/side-by-side.component';
import { SliderComponent } from './slider/slider.component';
import { SliderModule } from '@shared/slider/slider.module';
import { SolutionCardsComponent } from './solution-cards/solution-cards.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { VimeoComponent } from './vimeo/vimeo.component';
import { BackgroundImageComponent } from './background-image/background-image.component';
import { ResourceCardComponent } from './resource-cards/resource-card/resource-card.component';
import { FeatureCardComponent } from './feature-cards/feature-card/feature-card.component';
import { RouterModule } from '@angular/router';
import { SolutionCardComponent } from './solution-cards/solution-card/solution-card.component';
import { LandingComponent } from './landing/landing.component';
import { FaqComponent } from './faq/faq.component';

export const CUE_ELEMENT_TYPES = [
  ArticleHeaderComponent,
  BackgroundImageComponent,
  BulletinComponent,
  CallOutComponent,
  ColumnComponent,
  FeatureCardsComponent,
  FilterComponent,
  HeadlineLargeComponent,
  HeadlineMediumComponent,
  HeadlineMediumLargeComponent,
  HeadlineSmallComponent,
  HeadlineXSComponent,
  HeroComponent,
  HighlightComponent,
  HighlightLogoComponent,
  HighlightSpotsComponent,
  HighlightStatsComponent,
  HtmlSnippetComponent,
  ImageComponent,
  LeadComponent,
  LeadGeneratorComponent,
  LinkComponent,
  ParagraphLargeComponent,
  ParagraphMediumComponent,
  ParagraphSmallComponent,
  ResourceCardsComponent,
  SideBySideComponent,
  SliderComponent,
  SolutionCardsComponent,
  TestimonialComponent,
  VimeoComponent,
  LandingComponent,
  FaqComponent
];

@NgModule({
  declarations: [...CUE_ELEMENT_TYPES, ResourceCardComponent, FeatureCardComponent, SolutionCardComponent],
  imports: [CommonModule, HammerModule, CTAsModule, RouterModule, SharedModule, SliderModule, CueCoreModule],
  exports: [BackgroundImageComponent, SolutionCardComponent]
})
export class ComponentsModule { }
