import { Breakpoint } from '@services/breakpoint.service';
import { IImageSource } from '@shared/lazyload/lazyload.directive';
import { CueElement } from '../cue/cue-core/cue-content';
import { IAnnotationLink } from '../cue/cue-core/cue-content-mapper';
import { CueContentModel } from '../cue/cue-core/cue-content-model';
import { ICueElementMapper } from '../cue/cue-core/cue-element-mapper.interface';
import { ICueElementDto, ICueImageDto } from '../cue/cue-core/cue-interfaces';


// blue
// dark
// turquoise
// white

const getTheme = (color: string) => {
  switch (color) {
    case 'blue':
      return 'theme-dark';
    case 'dark':
      return 'theme-dark';
    case 'turquoise':
      return 'theme-green';
    case 'white':
      return 'theme-default';
    default:
      return 'theme-default';
  }
};

const getCtaTheme = (theme: string) => {
  switch (theme) {
    case 'theme-dark':
      return 'green';
    case 'theme-default':
      return 'default';
    case 'theme-green':
      return 'default';
    default:
      return 'default';
  }
};

const getChipTheme = (theme: string) => {
  switch (theme) {
    case 'theme-dark':
      return 'light';

    case 'theme-default':
      return 'default';

    case 'theme-green':
      return 'default';

    default:
      return 'default';
  }
};

const getRibbonTheme = (color: string) => {
  switch (color) {
    case 'dark blue':
      return 'ribbon-primary';
    case 'turquoise':
      return 'ribbon-secondary';
    case 'orange':
      return 'ribbon-orange';
    default:
      return 'ribbon-primary';
  }
};

@CueContentModel({ types: ['article_call_out'] })
export class ArticleCallOutModel extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  headline: string;
  authorName: string;
  authorTitle: string;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

    this.headline = (this.elements.find((n) => n instanceof HeadlineMediumModel) as HeadlineMediumModel)?.headline;
    this.authorName = (this.elements.find((n) => n instanceof AuthorNameModel) as AuthorNameModel)?.author;
    this.authorTitle = (this.elements.find((n) => n instanceof AuthorTitleModel) as AuthorTitleModel)?.author;
  }
}

@CueContentModel({ types: ['author_name'] })
export class AuthorNameModel extends CueElement {
  author: string;
  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
  }
}

@CueContentModel({ types: ['author_title'] })
export class AuthorTitleModel extends CueElement {
  author: string;
  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
  }
}

@CueContentModel({ types: ['bulletin_list'] })
export class BulletinListModel extends CueElement {

  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  // tslint:disable-next-line: variable-name
  is_numbered: string;

  isNumbered = false;

  elements: Array<BulletinModel>;
  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
    this.isNumbered = this.is_numbered === 'true';
  }
}

@CueContentModel({ types: ['bulletin'] })
export class BulletinModel extends CueElement {
  bulletin: string;
  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
  }
}

@CueContentModel({ types: ['column_item'] })
export class ColumnItemModel extends CueElement {
  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
  }
}

@CueContentModel({ types: ['column_module'] })
export class ColumnModuleModel extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
  }
}

@CueContentModel({ types: ['feature_card_module', 'filter_feature_card_module'] })
export class FeatureCardModuleModel extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  color: 'blue' | 'white';
  cards: FeatureCardModel[];

  theme: string;
  ctaTheme: string;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

    this.theme = getTheme(this.color);
    this.ctaTheme = getCtaTheme(this.theme);

    this.cards = this.elements.filter((n) => n instanceof FeatureCardModel) as FeatureCardModel[];
    this.elements = this.elements.filter((n) => !(n instanceof FeatureCardModel));
    this.elements.forEach((n) => (n instanceof LinkModel) ? n.theme = getCtaTheme(this.theme) : void (0));

  }
}

@CueContentModel({ types: ['feature_card', 'feature_card_with_image'] })
export class FeatureCardModel extends CueElement {
  image: ImageModel;

  headline: string;
  paragraph: string;
  link: LinkModel;
  imageSources: IImageSource[];

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

    this.headline = (this.elements.find((n) => n instanceof HeadlineSmallModel) as HeadlineSmallModel)?.headline;
    this.paragraph = (this.elements.find((n) => n instanceof ParagraphMediumModel) as ParagraphMediumModel)?.paragraph;
    this.image = this.elements.find((n) => n instanceof ImageModel || n instanceof LogoImageModel) as ImageModel;
    this.link = this.elements.find((n) => n instanceof LinkModel) as LinkModel;

    if (!!this.image) {
      this.imageSources = [
        { bp: Breakpoint.XS_MIN, url: this.image.crops.landscape480.href_full },
        { bp: Breakpoint.SM_MIN, url: this.image.crops.landscape768.href_full },
      ];
    }
  }
}

@CueContentModel({ types: ['filter_item'] })
export class FilterItemModel extends CueElement {
  // tslint:disable-next-line: variable-name
  filter_item: string;

  // tslint:disable-next-line: variable-name
  filter_text: string;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
  }
}

@CueContentModel({ types: ['filter_module'] })
export class FilterModuleModel extends CueElement {

  elements: FilterItemModel[];

  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
  }
}

@CueContentModel({ types: ['headline_large'] })
export class HeadlineLargeModel extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  placement: 'inner' | 'normal';

  headline: string;

  isCentered = true;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
    this.headline = this.headline.replace(/\xa0/gi, ' ');
    // this.isCentered = this.placement === 'inner';
  }
}

@CueContentModel({ types: ['headline_medium_large'] })
export class HeadlineMediumLargeModel extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  placement: 'inner' | 'normal';

  headline: string;

  isCentered = true;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
    this.headline = this.headline.replace(/\xa0/gi, ' ');
    // this.isCentered = this.placement === 'inner';
  }
}

@CueContentModel({ types: ['headline_medium'] })
export class HeadlineMediumModel extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  placement: 'inner' | 'normal';

  headline: string;

  isCentered = true;
  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
    this.headline = this.headline.replace(/\xa0/gi, ' ');

    this.isCentered = parent == null || parent.type !== 'landing_column';
  }
}

@CueContentModel({ types: ['headline_small'] })
export class HeadlineSmallModel extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  placement: 'inner' | 'normal';

  headline: string;

  isCentered = false;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
    this.headline = this.headline.replace(/\xa0/gi, ' ');
    this.isCentered = this.placement === 'inner';
  }
}

@CueContentModel({ types: ['headline_xs'] })
export class HeadlineXSModel extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  placement: 'inner' | 'normal';

  headline: string;

  isCentered = false;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
    this.headline = this.headline.replace(/\xa0/gi, ' ');
    this.isCentered = this.placement === 'inner';
  }
}

@CueContentModel({ types: ['hero_module'] })
export class HeroModuleModel extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  image: ImageModel;
  vimeo: VimeoModuleModel;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
    this.image = this.elements.find((n) => n instanceof ImageModel) as ImageModel;

    this.vimeo = this.elements.find((n) => n instanceof VimeoModuleModel) as VimeoModuleModel;

    this.elements = this.elements.filter((n) => !(n instanceof ImageModel) && !(n instanceof VimeoModuleModel));

    if (!!this.image) {
      this.image.imageSources = [
        { bp: Breakpoint.XS_MIN, url: this.image.crops.square.href_full },
        { bp: Breakpoint.LG_MAX, url: this.image.crops.square505.href_full }
      ];
      this.image.aspectRadio = 'ratio-1-1';
    }
    this.elements.forEach(n => (n as any).isCentered = false);
  }
}

@CueContentModel({ types: ['highlight_module'] })
export class HighlightModuleModel extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  color: 'blue' | 'white';

  link: string;

  theme: string;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

    this.theme = getTheme(this.color);

    this.elements.forEach((n) => (n instanceof LinkModel) ? n.theme = getCtaTheme(this.theme) : void (0));
  }
}

class CueImage extends CueElement {
  caption: string;

  // tslint:disable-next-line: variable-name
  alt_text: string;

  href: string;
  crops: ICueImageDto;

  imageSources: IImageSource[] = [];

  isLoaded = false;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

    if (!!element.relation) {
      this.href = element.relation.href;
      this.crops = element.relation.images;
    }

    if (!!this.crops) {
      this.imageSources = [
        { bp: Breakpoint.XS_MIN, url: this.crops.landscape480.href_full },
        { bp: Breakpoint.SM_MIN, url: this.crops.landscape560.href_full },
        { bp: Breakpoint.MD_MIN, url: this.crops.landscape768.href_full },
        { bp: Breakpoint.LG_MIN, url: this.crops.landscape.href_full },
      ];

      this.alt_text = this.crops.alt_text;
      this.caption = this.caption;
    }
  }
}

@CueContentModel({ types: ['image'] })
export class ImageModel extends CueImage {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  public aspectRadio: 'ratio-16-9' | 'ratio-1-1' = 'ratio-16-9';

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
  }
}

@CueContentModel({ types: ['background_image'] })
export class BackgroundImageModel extends CueImage {
  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
  }
}

@CueContentModel({ types: ['logo_item'] })
export class LogoImageModel extends CueImage {

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {

    super(mapper, element, parent);

    if (!!this.crops) {
      this.imageSources = [
        { bp: Breakpoint.XS_MIN, url: this.crops.custom160.href_full }
      ];
    }
  }
}


@CueContentModel({ types: ['internal_link'] })
export class InternalLinkModel extends CueElement implements IAnnotationLink {
  newWindow: boolean;
  noFollow: boolean;
  href: string;
  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement = null) {
    super(mapper, element, parent);
    if (element.relation !== null) {
      this.href = element.relation.href;
    }
  }
}

@CueContentModel({ types: ['external_link'] })
export class ExternalLinkModel extends CueElement implements IAnnotationLink {
  newWindow: boolean;
  noFollow: boolean;
  href: string;
  uri: string;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement = null) {
    super(mapper, element, parent);
    this.href = this.uri;
  }
}

@CueContentModel({ types: ['label'] })
export class LabelModel extends CueElement {
  label: string;
  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
  }
}

@CueContentModel({ types: ['lead_generator_module'] })
export class LeadGeneratorModuleModel extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  color: 'blue' | 'white';


  headline: string;
  paragraph: string;
  link: LinkModel;

  theme: string;
  ctaTheme: string;


  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
    this.headline = (this.elements.find((n) => n instanceof HeadlineMediumModel) as HeadlineMediumModel)?.headline;
    this.paragraph = (this.elements.find((n) => n instanceof ParagraphMediumModel) as ParagraphMediumModel)?.paragraph;
    this.link = (this.elements.find((n) => n instanceof LinkModel) as LinkModel);

    this.theme = getTheme(this.color);
    this.ctaTheme = getCtaTheme(this.theme);
  }
}

@CueContentModel({ types: ['link'] })
export class LinkModel extends CueElement {
  text: string;
  link: string;

  noFollow: boolean;
  newWindow: boolean;

  // tslint:disable-next-line: variable-name
  cta_type: 'primary' | 'secondary' | 'tertiary';

  theme = 'default';
  target: string = undefined;
  rel: string = undefined;

  isCentered = true;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

    if (!this.cta_type) {
      this.cta_type = 'primary';
    }

    if (this.newWindow) {
      this.target = '_blank';
    }

    if (!!this.noFollow) {
      this.rel = 'nofollow';
    }

    if (this.link?.includes('https://') || this.link?.includes('http://') || this.link?.includes('www.')) {
      this.rel = !!(this.rel) ? this.rel + ' noopener' : 'noopener';
    }

  }
}

@CueContentModel({ types: ['logo_ribbon_module'] })
export class LogoRibbonModuleModel extends CueElement {

  public elements: LogoImageModel[];

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
  }
}

@CueContentModel({ types: ['paragraph_large'] })
export class ParagraphLargeModel extends CueElement {

  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';

  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  placement: 'inner' | 'normal';

  paragraph: string;

  isCentered = false;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
    this.isCentered = this.placement === 'inner';

  }
}

@CueContentModel({ types: ['paragraph_medium'] })
export class ParagraphMediumModel extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  placement: 'inner' | 'normal';

  paragraph: string;

  isCentered = false;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
    this.isCentered = this.placement === 'inner';
  }
}

@CueContentModel({ types: ['paragraph_small'] })
export class ParagraphSmallModel extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  placement: 'inner' | 'normal';

  paragraph: string;

  isCentered = false;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

    this.isCentered = this.placement === 'inner';
  }
}

@CueContentModel({ types: ['quote'] })
export class QuoteModel extends CueElement {
  quote: string;
  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
  }
}

@CueContentModel({ types: ['relation'] })
export class RelationModel extends CueElement {
  title: string;
  summary: string;
  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
  }
}

@CueContentModel({ types: ['filter_resource_card_module', 'resource_card_module', 'resource_card_large_module'] })
export class ResourceCardModuleModel extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  // tslint:disable-next-line: variable-name
  is_featured: boolean;

  cards: ResourceCardModel[];
  isLarge: boolean;
  color: 'blue' | 'white';

  theme: string;
  ctaTheme: string;
  chipTheme: string;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

    this.theme = getTheme(this.color);
    this.ctaTheme = getCtaTheme(this.theme);
    this.chipTheme = getChipTheme(this.theme);

    this.isLarge = this.type === 'resource_card_large_module';
    this.cards = this.elements.filter((n) => n instanceof ResourceCardModel) as ResourceCardModel[];
    this.elements = this.elements.filter((n) => !(n instanceof ResourceCardModel));

    this.elements.forEach((n) => (n instanceof LinkModel) ? n.theme = getCtaTheme(this.theme) : void (0));

  }
}

@CueContentModel({ types: ['resource_card', 'resource_card_large', 'resource_card_with_image'] })
export class ResourceCardModel extends CueElement {
  timeToRead: number;
  label: string;
  image: ImageModel;

  headline: string;
  paragraph: string;
  link: LinkModel;

  imageSources: IImageSource[];

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

    this.timeToRead = (this.elements.find((n) => n instanceof TimeToReadModel) as TimeToReadModel)?.time_to_read;
    this.label = (this.elements.find((n) => n instanceof LabelModel) as LabelModel)?.label;
    this.headline = (this.elements.find((n) => n instanceof HeadlineSmallModel) as HeadlineSmallModel)?.headline;
    this.paragraph = (this.elements.find((n) => n instanceof ParagraphMediumModel) as ParagraphMediumModel)?.paragraph;
    this.image = this.elements.find((n) => n instanceof ImageModel) as ImageModel;
    this.link = this.elements.find((n) => n instanceof LinkModel) as LinkModel;

    if (!!this.image) {
      this.imageSources = [
        { bp: Breakpoint.XS_MIN, url: this.image.crops.landscape480.href_full },
        { bp: Breakpoint.SM_MIN, url: this.image.crops.landscape768.href_full },
      ];
    }
  }
}

@CueContentModel({ types: ['side_by_side_module'] })
export class SideBySideModuleModel extends CueElement {

  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  placement: 'text_left' | 'text_right';
  color: 'blue' | 'grey' | 'blue_grey' | 'grey_blue' | string;

  link: string;
  image: ImageModel;

  imageSources: IImageSource[];
  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
    this.image = this.elements.find((n) => n instanceof ImageModel) as ImageModel;
    this.elements = this.elements.filter((n) => !(n instanceof ImageModel));
    this.elements.forEach((n) => ((n as any).isCentered ? ((n as any).isCentered = false) : void 0));

    if (!!this.color) {
      this.color = this.color.replace('/', '_');
    }

    if (!!this.image) {
      this.imageSources = [
        { bp: Breakpoint.XS_MIN, url: this.image.crops.landscape480.href_full },
        { bp: Breakpoint.SM_MIN, url: this.image.crops.landscape768.href_full },
        { bp: Breakpoint.MD_MIN, url: this.image.crops.landscape.href_full }
      ];
    }
  }
}

@CueContentModel({ types: ['slider_item'] })
export class SliderItemModel extends CueElement {
  image: ImageModel;
  headline: string;
  paragraph: string;
  link: LinkModel;

  imageSources: IImageSource[];

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

    this.headline = (this.elements.find((n) => n instanceof HeadlineXSModel) as HeadlineXSModel)?.headline;
    this.paragraph = (this.elements.find((n) => n instanceof ParagraphMediumModel) as ParagraphMediumModel)?.paragraph;
    this.image = this.elements.find((n) => n instanceof ImageModel) as ImageModel;
    this.link = this.elements.find((n) => n instanceof LinkModel) as LinkModel;

    if (!!this.image) {
      this.imageSources = [
        { bp: Breakpoint.XS_MIN, url: this.image.crops.landscape480.href_full },
        { bp: Breakpoint.SM_MIN, url: this.image.crops.landscape560.href_full },
        { bp: Breakpoint.MD_MIN, url: this.image.crops.landscape768.href_full },
        // { bp: Breakpoint.LG_MIN, url: this.image.crops.landscape.href_full },
      ];
    }
  }
}

@CueContentModel({ types: ['slider_module'] })
export class SliderModuleModel extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  slider: SliderModel;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

    this.slider = this.elements.find((n) => n instanceof SliderModel) as SliderModel;
    this.elements = this.elements.filter((n) => !(n instanceof SliderModel));
  }
}

@CueContentModel({ types: ['slider'] })
export class SliderModel extends CueElement {
  slides: SliderItemModel[] = [];

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

    this.slides = this.elements.filter((n) => n instanceof SliderItemModel) as SliderItemModel[];
    this.elements = this.elements.filter((n) => !(n instanceof SliderItemModel));
  }
}

@CueContentModel({ types: ['solution_card_module'] })
export class SolutionCardModuleModel extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  cards: SolutionCardModel[];

  theme = 'theme-default';
  ctaTheme: string;

  // tslint:disable-next-line: max-line-length
  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

    // this.theme = getTheme(this.color);
    this.ctaTheme = getCtaTheme(this.theme);

    this.cards = this.elements.filter((n) => n instanceof SolutionCardModel) as SolutionCardModel[];
    this.elements = this.elements.filter((n) => !(n instanceof SolutionCardModel));
  }
}

@CueContentModel({ types: ['solution_card'] })
export class SolutionCardModel extends CueElement {
  color: 'dark blue' | 'orange' | 'turquoise';
  headline: string;
  paragraph: string;
  link: LinkModel;

  bulletin: BulletinListModel;

  ribbonTheme: string;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

    this.ribbonTheme = getRibbonTheme(this.color);

    this.headline = (this.elements.find((n) => n instanceof HeadlineSmallModel) as HeadlineSmallModel)?.headline;
    this.paragraph = (this.elements.find((n) => n instanceof ParagraphMediumModel) as ParagraphMediumModel)?.paragraph;
    this.link = this.elements.find((n) => n instanceof LinkModel) as LinkModel;
    this.bulletin = this.elements.find((n) => n instanceof BulletinListModel) as BulletinListModel;
  }
}

@CueContentModel({ types: ['spot_item_module'] })
export class SpotItemModuleModel extends CueElement {

  elements: SpotItemModel[];

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

  }
}

@CueContentModel({ types: ['spot_item'] })
export class SpotItemModel extends CueElement {

  headline: string;
  paragraph: string;
  link: LinkModel;
  image: ImageModel;
  imageSources: IImageSource[];

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

    this.headline = (this.elements.find((n) => n instanceof HeadlineXSModel) as HeadlineXSModel)?.headline;
    this.paragraph = (this.elements.find((n) => n instanceof ParagraphMediumModel) as ParagraphMediumModel)?.paragraph;
    this.image = this.elements.find((n) => n instanceof ImageModel) as ImageModel;

    if (!!this.image) {
      this.imageSources = [
        { bp: Breakpoint.XS_MIN, url: this.image.crops.square85.href_full }
      ];
    }
  }
}

@CueContentModel({ types: ['stats_item_module'] })
export class StatsItemsModuleModel extends CueElement {

  elements: StatsItemModel[];

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
  }
}

@CueContentModel({ types: ['stats_item'] })
export class StatsItemModel extends CueElement {
  link: LinkModel;
  headline: string;
  paragraph: string;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

    this.headline = (this.elements.find((n) => n instanceof HeadlineMediumLargeModel) as HeadlineMediumLargeModel)?.headline;
    this.paragraph = (this.elements.find((n) => n instanceof ParagraphMediumModel) as ParagraphMediumModel)?.paragraph;
    this.link = this.elements.find((n) => n instanceof LinkModel) as LinkModel;
  }
}


@CueContentModel({ types: ['testimonial_item'] })
export class TestimonialItemModel extends CueElement {

  image: ImageModel;
  quote: string;

  authorName: string;
  authorTitle: string;
  authorImage: LogoImageModel;
  link: LinkModel;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

    this.quote = (this.elements.find((n) => n instanceof QuoteModel) as QuoteModel)?.quote;

    this.image = this.elements.find((n) => n instanceof ImageModel) as ImageModel;

    this.authorName = (this.elements.find((n) => n instanceof AuthorNameModel) as AuthorNameModel)?.author;
    this.authorTitle = (this.elements.find((n) => n instanceof AuthorTitleModel) as AuthorTitleModel)?.author;
    this.authorImage = this.elements.find((n) => n instanceof LogoImageModel) as LogoImageModel;

    this.link = this.elements.find((n) => n instanceof LinkModel) as LinkModel;

    if (!!this.image) {
      this.image.imageSources = [
        { bp: Breakpoint.XS_MIN, url: this.image.crops.square.href_full },
        { bp: Breakpoint.LG_MIN, url: this.image.crops.square505.href_full }
      ];
    }

    if (!!this.authorImage) {
      this.authorImage.imageSources = [
        { bp: Breakpoint.XS_MIN, url: this.authorImage.crops.custom48.href_full }
      ];
    }
  }
}

@CueContentModel({ types: ['testimonial_module', 'filter_testimonial_module'] })
export class TestimonialModuleModel extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  elements: TestimonialItemModel[];

  color: 'blue' | 'white' | 'dark' | 'turquoise';

  theme: string;
  ctaTheme: string;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

    this.theme = getTheme(this.color);
    this.ctaTheme = getCtaTheme(this.theme);
    this.elements.forEach((n) => (n instanceof LinkModel) ? n.theme = getCtaTheme(this.theme) : void (0));
  }
}

@CueContentModel({ types: ['time_to_read'] })
export class TimeToReadModel extends CueElement {
  // tslint:disable-next-line: variable-name
  time_to_read: number;
  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
  }
}

@CueContentModel({ types: ['html_snippet'] })
export class HtmlSnippetModel extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  // tslint:disable-next-line: variable-name
  html_snippet: string;
  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
  }
}

@CueContentModel({ types: ['lead_text'] })
export class LeadTextModel extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  // tslint:disable-next-line: variable-name
  lead_text: string;
  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
  }
}

@CueContentModel({ types: ['article_header'] })
export class ArticleHeaderModel extends CueElement {
  // tslint:disable-next-line: variable-name
  twitter_share: string;
  // tslint:disable-next-line: variable-name
  linkedin_share: string;
  // tslint:disable-next-line: variable-name
  facebook_share: string;

  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';

  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  // tslint:disable-next-line: variable-name
  publication_date: string;

  timeToRead: number;
  headline: string;
  label: string;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
    this.timeToRead = (this.elements.find((n) => n instanceof TimeToReadModel) as TimeToReadModel)?.time_to_read;
    this.headline = (this.elements.find((n) => n instanceof HeadlineMediumLargeModel) as HeadlineMediumLargeModel)?.headline;
    this.label = (this.elements.find((n) => n instanceof LabelModel) as LabelModel)?.label;
    this.publication_date = (element as any).publication_date;

  }
}

@CueContentModel({ types: ['background_image_module'] })
export class BackgroundImageModuleModel extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';

  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  backgroundImage: BackgroundImageModel;
  htmlSnippet: HtmlSnippetModel;

  elements: Array<HeadlineMediumModel | ParagraphMediumModel | LinkModel | HtmlSnippetModel | BackgroundImageModel>;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

    this.backgroundImage = this.elements.find((n) => n instanceof BackgroundImageModel) as BackgroundImageModel;
    this.htmlSnippet = this.elements.find((n) => n instanceof HtmlSnippetModel) as HtmlSnippetModel;

    this.elements = this.elements.filter((n) => !(n instanceof BackgroundImageModel) && !(n instanceof HtmlSnippetModel));
  }
}

@CueContentModel({ types: ['vimeo_item'] })
export class VimeoItemModel extends CueElement {
  // tslint:disable-next-line: variable-name
  video_id: string;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
  }
}

@CueContentModel({ types: ['vimeo_module'] })
export class VimeoModuleModel extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';

  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  elements: Array<VimeoItemModel | ImageModel>;

  vimeoId: string;

  // tslint:disable-next-line: variable-name
  is_silent: string;
  isSilent: boolean;

  imageSources: IImageSource[];
  image: ImageModel;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

    this.vimeoId = (this.elements.find((n) => n instanceof VimeoItemModel) as VimeoItemModel)?.video_id;
    this.image = this.elements.find((n) => n instanceof ImageModel) as ImageModel;
    // tslint:disable-next-line: triple-equals
    this.isSilent = this.is_silent == 'true';
    // Test overrided
    // this.vimeoId = '398636593';
    // this.is_silent = false;

    if (!!this.image) {
      this.imageSources = [
        { bp: Breakpoint.XS_MIN, url: this.image.crops.landscape480.href_full },
        { bp: Breakpoint.SM_MIN, url: this.image.crops.landscape560.href_full },
        { bp: Breakpoint.MD_MIN, url: this.image.crops.landscape768.href_full },
        { bp: Breakpoint.LG_MIN, url: this.image.crops.landscape.href_full },
      ];
    }
  }
}

@CueContentModel({ types: ['landing_column'] })
export class LandingColumn extends CueElement {
  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);
  }
}


@CueContentModel({ types: ['landing_module'] })
export class LandingModule extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  image: ImageModel;
  column: LandingColumn;
  html: HtmlSnippetModel;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

    this.image = this.elements.find((n) => n instanceof ImageModel) as ImageModel;
    this.column = this.elements.find((n) => n instanceof LandingColumn) as LandingColumn;
    this.html = this.elements.find((n) => n instanceof HtmlSnippetModel) as HtmlSnippetModel;

    if (!!this.image) {
      this.image.imageSources = [
        { bp: Breakpoint.XS_MIN, url: this.image.crops.custom640.href_full },
      ];
    }

  }
}

interface IFaqOptions {
  color: string;
  spacingTop: 'none' | 'small' | 'medium' | 'large';
  spacingBottom: 'none' | 'small' | 'medium' | 'large';
}
export interface IFaqChild {
  question: string;
  answer: string;
  expanded: boolean;
}

@CueContentModel({ types: ['faq'] })
export class FaqModule extends CueElement {
  // tslint:disable-next-line: variable-name
  spacing_top: 'none' | 'small' | 'medium' | 'large';
  // tslint:disable-next-line: variable-name
  spacing_bottom: 'none' | 'small' | 'medium' | 'large';

  children: IFaqChild[];

  color: string;

  constructor(mapper: ICueElementMapper, element: ICueElementDto, parent: CueElement) {
    super(mapper, element, parent);

    this.children = (element as any).children as IFaqChild[];
    const options = (element as any).options as IFaqOptions | undefined;

    if (!!options) {
      this.color = options.color;
      this.spacing_bottom = options.spacingBottom;
      this.spacing_top = options.spacingTop;
    }
  }
}

export const CUE_ELEMENT_MODELS = [
  ArticleCallOutModel,
  ArticleHeaderModel,
  AuthorNameModel,
  AuthorTitleModel,
  BackgroundImageModel,
  BackgroundImageModuleModel,
  BulletinListModel,
  BulletinModel,
  ColumnItemModel,
  ColumnModuleModel,
  FeatureCardModel,
  FeatureCardModuleModel,
  FilterItemModel,
  // FilterFeatureCardModuleModel,
  FilterModuleModel,
  // FilterTestimonialModuleModel,
  HeadlineLargeModel,
  HeadlineMediumLargeModel,
  HeadlineMediumModel,
  HeadlineSmallModel,
  HeadlineXSModel,
  HeroModuleModel,
  HighlightModuleModel,
  ImageModel,
  InternalLinkModel,
  ExternalLinkModel,
  LabelModel,
  LeadGeneratorModuleModel,
  LeadTextModel,
  LinkModel,
  LogoImageModel,
  LogoRibbonModuleModel,
  ParagraphLargeModel,
  ParagraphMediumModel,
  ParagraphSmallModel,
  QuoteModel,
  RelationModel,
  HtmlSnippetModel,
  ResourceCardModel,
  ResourceCardModuleModel,
  SideBySideModuleModel,
  SliderItemModel,
  SliderModel,
  SliderModuleModel,
  SolutionCardModel,
  SolutionCardModuleModel,
  SpotItemModel,
  SpotItemModuleModel,
  StatsItemModel,
  StatsItemsModuleModel,
  TestimonialItemModel,
  TestimonialModuleModel,
  TimeToReadModel,
  VimeoItemModel,
  VimeoModuleModel,
  LandingColumn,
  LandingModule,
  FaqModule
];
