import { Component, Input } from '@angular/core';

@Component({
  selector: 'sdx-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {

  @Input()
  public color = '#535ea0';

  @Input()
  public layout: 'flex' | 'absolute' | 'fixed';
}
