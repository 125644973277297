<div class="container-01" (sdxInView)="inView = true" [@groupStagger]="inView">

  <sdx-cue-content-portal *ngFor="let e of cueElement.elements; trackBy: trackByFnc" [content]="e"></sdx-cue-content-portal>
  <div class="row v-gutters align-items-stretch justify-content-center">
    <div *ngFor="let card of cueElement.cards; trackBy: trackByFnc" class="col-12 col-lg-4">
      <sdx-solution-card [card]="card" [theme]="cueElement.theme" [ctaTheme]="cueElement.ctaTheme" [inView]="inView"></sdx-solution-card>
    </div>
  </div>

</div>
