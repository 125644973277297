import { Component, HostBinding, OnInit } from '@angular/core';
import { CueContentType, CueElementAware } from '@cue-core';
import { LeadGeneratorModuleModel } from '@core/content-models';
import { AnimationStateService } from '@services/animation-state.service';
import { BaseComponent } from '@core/base.component';
import { staggerIn } from '@core/animations';

@CueContentType({ types: ['lead_generator_module'] })
@Component({
  selector: 'sdx-lead-generator',
  templateUrl: './lead-generator.component.html',
  styleUrls: ['./lead-generator.component.scss'],
  animations: [staggerIn]
})
export class LeadGeneratorComponent extends BaseComponent implements OnInit, CueElementAware {

  cueElement: LeadGeneratorModuleModel;

  @HostBinding('class')
  get hostClasses(): string[] {
    return ['spacing-top-' + this.cueElement.spacing_top, 'spacing-bottom-' + this.cueElement.spacing_bottom];
  }

  public inView = false;

  constructor(private animationStateService: AnimationStateService) {
    super();
  }

  ngOnInit() {
    this.animationStateService.disabled
      .pipe(this.takeUntilDestroyed())
      .subscribe((disabled) => this.disableAnimations = disabled);
  }
}
