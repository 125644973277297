import { InjectionToken, Type } from '@angular/core';
import 'reflect-metadata';
import { Resolve } from '@angular/router';

export interface CueContentType {
    types: string[];
    resolve?: { [name: string]: Type<Resolve<any>>; };
    canActivate?: Type<any>[];
}

export function CueContentType(contentType: CueContentType) {
    return <T extends new (...args: any[]) => {}>(constructor: T) => {
      Reflect.defineMetadata('cueContentType', contentType, constructor);
    };
}


export const CUE_CONTENT_TYPES = new InjectionToken<Type<any>[][]>('CUE_CONTENT_TYPES');
