import { flatten } from '@angular/compiler';
import { Inject, Injectable, Type } from '@angular/core';
import { CueElement } from './cue-content';
import { CueContentModel, CUE_CONTENT_MODELS } from './cue-content-model';

export interface ContentModelDef {
  type: string;
  model: Type<CueElement>;
}

@Injectable({
  providedIn: 'root',
})
export class CueContentModelProvider {
  private contentTypeDefMap: Map<string, ContentModelDef> = new Map<string, ContentModelDef>();

  constructor(@Inject(CUE_CONTENT_MODELS) documentTypes: Type<any>[][]) {
    const types = flatten(documentTypes);

    for (const model of types) {
      const metadata = Reflect.getMetadata('cueContentModel', model) as CueContentModel;
      if (metadata && metadata.types) {
        metadata.types.forEach((type) => {
          if (this.contentTypeDefMap.has(type)) {
            throw Error(model.name + ' is defined multiple times with the content type type "' + type + '"');
          }
          this.contentTypeDefMap.set(type, { model, type });
        });
      } else {
        throw Error('Cannot register model. ' + model.name + ' is not at content model');
      }
    }
  }

  public get(type: string) {
    return this.contentTypeDefMap.get(type);
  }

  public has(type: string) {
    return this.contentTypeDefMap.has(type);
  }
}
