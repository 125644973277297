import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { CueContent } from '@cue-core';

@Component({
  selector: 'sdx-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  @HostBinding('class.hide-toolbar')
  public hideToolbar = false;

  private lastScrollY = 0;

  @Input()
  public cueContent: CueContent;

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (window.scrollY < 0) {
      return;
    }
    this.hideToolbar = (this.lastScrollY < window.scrollY);
    this.lastScrollY = window.scrollY;
  }
}
