import { FeatureCardModuleModel, InternalLinkModel, ResourceCardModuleModel, TestimonialModuleModel } from '@cue-models';
import { CueElement } from './cue-content';
import { CueContentModelProvider } from './cue-content-model-provider';
import { ICueElementMapper } from './cue-element-mapper.interface';
import { ICueElementDto, ICueFitlerDto } from './cue-interfaces';

export class CueFilterMapper implements ICueElementMapper {

  private rootElements: CueElement[] = [];
  private elementMap = new Map<string, any>();
  private annotationLinks = new Map<string, InternalLinkModel>();

  private allowedTypes = [
    'filter_feature_card_module',
    'filter_resource_card_module',
    'filter_testimonial_module'
  ];

  constructor(private contentModelProvider: CueContentModelProvider, content: ICueFitlerDto) {

    const childSet = new Set<string>();
    content.elements.forEach((element) => {

      if (element.type === 'internal_link' && element.relation !== null) {
        const model = new InternalLinkModel(this, element);
        this.annotationLinks.set(element.relation.id, model);
        return;
      }

      if (!element.id) {
        console.error(`Element: has no Id!`, element);
        return;
      }

      this.elementMap.set(element.id, element);
      if (!!element.children) {
        element.children.forEach((id) => childSet.add(id));
      }
    });

    this.rootElements = content.elements
      .filter((rtEl) => this.allowedTypes.includes(rtEl.type))
      .map((n) => this.mapElement(n, null));


    if (this.rootElements.length === 0) {
      // console.warn(`"${content.title}" => has neither testimonial or feature/resource card module!`);
    } else {
      // tslint:disable-next-line: max-line-length
      const cardModule = this.rootElements.find(n => n instanceof ResourceCardModuleModel || n instanceof FeatureCardModuleModel) as ResourceCardModuleModel;
      const testimonialModule = this.rootElements.find(n => n instanceof TestimonialModuleModel);

      if (!cardModule) {
        // console.warn(`"${content.title}" => has no card module!`);
      } else if (cardModule.cards.length === 0) {
        // console.warn(`"${content.title}" => ${cardModule.type} has no card!`);
      }

      if (!testimonialModule) {
        // console.warn(`"${content.title}" => is missing a testimonial card module!`);
      }
      else if (testimonialModule.elements.length === 0) {
        // console.warn(`"${content.title}" => ${testimonialModule.type} has no testimonial item!`);
      }
    }


  }

  private mapElement(elementDto: ICueElementDto, parent: CueElement) {
    if (this.contentModelProvider.has(elementDto.type)) {
      const ref = this.contentModelProvider.get(elementDto.type);
      return new ref.model(this, elementDto, parent);
    }
    return new CueElement(this, elementDto, parent);
  }

  public getElement(id: string, parent: CueElement) {
    const elementDto = this.elementMap.get(id);
    if (!!elementDto) {
      return this.mapElement(elementDto, parent);
    }
    console.error(`Element: ${id} not found!`);
  }

  public getElements() {
    return this.rootElements;
  }

  public getLink(id: string) {
    return this.annotationLinks.get(id);
  }
}
