import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'sdx-carousel-indicator',
  templateUrl: './carousel-indicator.component.html',
  styleUrls: ['./carousel-indicator.component.scss'],
})
export class CarouselIndicatorComponent implements OnChanges {
  @Input()
  public theme: 'default' | 'green' | 'light' = 'default';

  @Input()
  public amount: number;

  @Input()
  public current: number;

  @Output()
  public changeCurrent = new EventEmitter<number>();

  public items = [];

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.amount &&
      changes.amount.currentValue !== changes.amount.previousValue
    ) {
      this.items = Array.from(Array(this.amount).keys());
    }
  }

  public change(i: number) {
    if (this.current !== i) {
      this.changeCurrent.next(i);
    }
  }

  public trackByIndex = (i: number, n: number) => i;
}
