<section class="p-horizontal">
  <div class="filter-wrapper">
    <span class="filter-left" (click)="scrollLeft()" [ngClass]="{'is-disabled': translateX == 0 || !scrollEnabled}"></span>
    <div class="filter-container" #filterContainer (panstart)="panStart($event)" (panmove)="panMove($event)" (panend)="panEnd($event)" (pancancel)="panCancel($event)">
      <ul class="filter-list" #filterList>
        <li *ngFor="let n of cueElement.elements; trackBy: trackByFnc" class="filter-item"><span class="filter-item-action" [ngClass]="{'is-active': n == current}" (click)="select(n)">{{n.filter_text}}</span></li>
      </ul>
    </div>
    <span class="filter-right" (click)="scrollRight()" [ngClass]="{'is-disabled': (panelWidth - translateX ) >= listWidth || !scrollEnabled}"></span>
  </div>
</section>
<sdx-loader *ngIf="isLoading" class="mt-3" [layout]="'flex'"></sdx-loader>
<div *ngIf="error" class="text-center text-danger mt-3"><strong>Error: {{error}}</strong></div>
<div class="container-01 p-vertical">
  <div class="row v-gutters align-items-stretch justify-content-center" [@staggerInOut]='items.length'>
    <div *ngFor="let item of items;trackBy: trackByFnc; let i = index" class="col-12" [ngClass]="isFeatured(i) ? 'col-lg-6' : isTestimonial(i) ? '': 'col-lg-4'">
      <sdx-feature-card *ngIf="item.featureCard && !isTestimonial(i)" [card]="item.featureCard" [theme]="'theme-default'" [ctaTheme]="'default'" [imagePadding]="true"></sdx-feature-card>
      <sdx-resource-card *ngIf="item.resourceCard && !isTestimonial(i)" [card]="item.resourceCard" [theme]="'theme-default'" [ctaTheme]="'default'" [chipTheme]="'default'" [isLarge]="isFeatured(i)"></sdx-resource-card>
      <div class="filter-testimonial-container" *ngIf="isTestimonial(i)">
        <sdx-cue-content-portal [content]="item.testimonialModule"></sdx-cue-content-portal>
      </div>
    </div>
  </div>
</div>
