import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { OpacityLayerService } from './opacity-layer.service';
import { debounceTime, filter } from 'rxjs/operators';
import { AnimationEvent } from '@angular/animations';

import { trigger, state, style, transition, animate } from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'sdx-opacity-layer',
  templateUrl: './opacity-layer.component.html',
  styleUrls: ['./opacity-layer.component.scss'],
  animations: [
    trigger('display', [
      state('true', style({ opacity: 1 })),
      state('void', style({ opacity: 0 })),
      transition(':enter', animate('200ms ease-in-out')),
      transition(':leave', animate('200ms ease-in-out')),
    ]),
  ],
})
export class OpacityLayerComponent implements OnInit {
  public isActive = false;

  private scrolly = 0;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private opacityLayerService: OpacityLayerService,
    private router: Router
  ) { }

  public ngOnInit(): void {
    this.opacityLayerService.isActive
      .pipe(debounceTime(5))
      .subscribe((state) => (this.isActive = state !== null));

    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => this.opacityLayerService.disable());
  }

  animationStarted(event: AnimationEvent) {
    if (event.fromState === 'void') {
      this.scrolly = (window as any).scrollY;
      this.renderer.setStyle(this.document.body, 'overflow', 'hidden');
    }
  }

  animationDone(event: AnimationEvent) {
    if (event.toState === 'void') {
      (window as any).scrollTo(0, this.scrolly); // values are x,y-offset
      this.renderer.setStyle(this.document.body, 'overflow', '');
    }
  }
}
