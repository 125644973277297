import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ResourceCardModel } from '@cue-models';

@Component({
  selector: 'sdx-resource-card',
  templateUrl: './resource-card.component.html',
  styleUrls: ['./resource-card.component.scss']
})
export class ResourceCardComponent {

  @Input()
  theme: string;

  @Input()
  ctaTheme: string;

  @Input()
  chipTheme: string;

  @Input()
  card: ResourceCardModel;

  @Input()
  isLarge: boolean;

  public isHovered = false;
  public inView = false;
}
