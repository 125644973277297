import { trigger, transition, style, animate, state } from '@angular/animations';
import { Component, HostBinding, OnInit } from '@angular/core';
import { CueContentType, CueElement, CueElementAware } from '@cue-core';
import { SliderItemModel, SliderModuleModel } from '@core/content-models';
import { defaultEasing } from '@core/animations';

@CueContentType({ types: ['slider_module'] })
@Component({
  selector: 'sdx-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  animations: [
    trigger('animateText', [
      state('true', style({ transform: 'translateX(0)', opacity: 1, })),
      state('false', style({ transform: 'translateX(50px)', opacity: 0, })),
      transition('true => false', [animate('100ms ' + defaultEasing)]),
      transition('false => true', [animate('500ms ' + defaultEasing)]),
    ]),
  ],
})
export class SliderComponent implements OnInit, CueElementAware {
  public ready = false;
  public inView = false;

  public cueElement: SliderModuleModel;

  @HostBinding('class')
  get hostClasses(): string[] {
    return ['spacing-top-' + this.cueElement.spacing_top, 'spacing-bottom-' + this.cueElement.spacing_bottom];
  }

  public currentIndex = 0;

  ngOnInit(): void {
    this.currentIndex = this.cueElement.slider.slides.length;
  }

  public multiply(items: SliderItemModel[]) {
    return [...items, ...items, ...items];
  }

  public setIndex(i: number) {
    setTimeout(() => (this.currentIndex = i), 0);
  }

  public trackByFnc = (i: number, n: CueElement) => n.id;
  public trackByIndex = (i: number, n: CueElement) => i;

}
