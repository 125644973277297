import { animation, animate, style, stagger, trigger, query, transition, useAnimation, animateChild } from '@angular/animations';

export const defaultEasing = 'cubic-bezier(0.15, 1, 0.3, 1)'; // https://easings.net/#easeOutExpo

export const animationConfig = {
  easing: defaultEasing,
  duration: '300ms',
  staggerDelay: '200ms',
};

export const animateStaggerIn = animation([
  style({ opacity: 0, transform: 'translateX(1vw)' }),
  stagger(animationConfig.staggerDelay, [
    animate(`${animationConfig.duration} 100ms ${animationConfig.easing}`, style({ opacity: 1, transform: 'none' })),
  ]),
]);


export const animateOut = animation([
    animate(`${animationConfig.duration} ${animationConfig.easing}`, style({ opacity: 0, transform: 'translateX(-1vw)' })),
]);

export const staggerIn = trigger('staggerIn', [
  transition('false => true', [query('.stagger-in', useAnimation(animateStaggerIn), { optional: true })]),
]);

export const groupStagger = trigger('groupStagger', [
  transition('false => true', [
    query('.card-title, .card-paragraph ', [style({ opacity: 0, transform: 'translateX(1vw)' })]),
    query('.card-title', animate(`${animationConfig.duration} ${animationConfig.easing}`, style({ opacity: 1, transform: 'none' })), {
      optional: true,
    }),
    query('.card-paragraph', animate(`${animationConfig.duration} ${animationConfig.easing}`, style({ opacity: 1, transform: 'none' })), {
      optional: true,
    }),
    query('@staggerIn', animateChild(), { optional: true }),
  ]),
]);

export const staggerInOut = trigger('staggerInOut', [
  transition('* <=> *', [
    query(':enter', [useAnimation(animateStaggerIn)], { optional: true }),
    query(':leave', [useAnimation(animateOut)], { optional: true })
  ])
])
