import { Component, HostBinding, OnInit } from '@angular/core';
import { CueContentType, CueElementAware } from '@cue-core';
import { LeadTextModel } from '@core/content-models';
import { AnimationStateService } from '@services/animation-state.service';
import { BaseComponent } from '@core/base.component';
import { staggerIn } from '@core/animations';

@CueContentType({ types: ['lead_text'] })
@Component({
  selector: 'sdx-lead',
  templateUrl: './lead.component.html',
  styleUrls: ['./lead.component.scss'],
  animations: [staggerIn]
})
export class LeadComponent  extends BaseComponent implements OnInit, CueElementAware {
  public cueElement: LeadTextModel;

  @HostBinding('class')
  get hostClasses(): string[] {
    return ['spacing-top-' + this.cueElement.spacing_top, 'spacing-bottom-' + this.cueElement.spacing_bottom];
  }

  public inView = false;

  constructor(private animationStateService: AnimationStateService) {
    super();
  }

  ngOnInit() {
    this.animationStateService.disabled
      .pipe(this.takeUntilDestroyed())
      .subscribe((disabled) => this.disableAnimations = disabled);
  }

}
