import { Route, UrlMatchResult, UrlSegment, UrlSegmentGroup } from '@angular/router';

export function cueUrlMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult {
  if (route.children && route.children.length > 0) {
    const consumedRoute = [];

    for (const segment of segments) {
      if (route.children.find((x) => x.path === segment.path)) {
        break;
      }

      consumedRoute.push(segment);
    }

    return { consumed: consumedRoute };
  }

  return { consumed: segments };
}
