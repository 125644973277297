import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'sdx-slider-indicator',
  templateUrl: './slider-indicator.component.html',
  styleUrls: ['./slider-indicator.component.scss'],
})
export class SliderIndicatorComponent {
  @Input()
  public theme: 'default' | 'green' | 'light' = 'default';

  @Input()
  public amount: number;

  @Input()
  public current: number;

  @Output()
  public next = new EventEmitter<number>();

  @Output()
  public previous = new EventEmitter<number>();

  public getValue(value: number): string {
    if (value < 10) {
      return '0' + value;
    }
    return value.toString();
  }
}
