import { Component, OnInit } from '@angular/core';
import { CueContentType, CueElement, CueElementAware } from '@cue-core';
import { StatsItemsModuleModel } from '@core/content-models';
import { BaseComponent } from '@core/base.component';
import { AnimationStateService } from '@services/animation-state.service';

@CueContentType({ types: ['stats_item_module'] })
@Component({
  selector: 'sdx-highlight-stats',
  templateUrl: './highlight-stats.component.html',
  styleUrls: ['./highlight-stats.component.scss'],
})
export class HighlightStatsComponent extends BaseComponent implements OnInit, CueElementAware {

  cueElement: StatsItemsModuleModel;

  public inView = false;

  constructor(private animationStateService: AnimationStateService) {
    super();
  }

  ngOnInit() {
    this.animationStateService.disabled
      .pipe(this.takeUntilDestroyed())
      .subscribe((disabled) => this.disableAnimations = disabled);
  }

  public trackByFnc = (i: number, n: CueElement) => n.id;

}
