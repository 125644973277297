<div class="container-03">
  <div class="row v-gutters no-gutters">
    <div class="col-12 col-md-6 hero-image-col" *ngIf="cueElement.image">
      <div class="hero-image">
        <sdx-cue-content-portal [content]="cueElement.image"></sdx-cue-content-portal>
      </div>
    </div>

    <div class="col-12 col-md-6 hero-vimeo-col" *ngIf="cueElement.vimeo">
      <div class="hero-vimeo">
        <div class="hero-vimeo-inner">
          <sdx-cue-content-portal [content]="cueElement.vimeo"></sdx-cue-content-portal>
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 hero-content-col">
      <div class="hero-content">
        <sdx-cue-content-portal *ngFor="let e of cueElement.elements; trackBy: trackByFnc" [content]="e"></sdx-cue-content-portal>
      </div>
    </div>
  </div>
</div>
