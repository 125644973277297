import { Component, HostBinding } from '@angular/core';
import { CueContentType, CueElement, CueElementAware } from '@cue-core';
import { HighlightModuleModel } from '@core/content-models';
import { staggerIn } from '@core/animations';

@CueContentType({ types: ['highlight_module'] })
@Component({
  selector: 'sdx-highlight',
  templateUrl: './highlight.component.html',
  styleUrls: ['./highlight.component.scss'],
  animations: [staggerIn],
})
export class HighlightComponent implements CueElementAware {
  public cueElement: HighlightModuleModel;

  @HostBinding('class')
  get hostClasses(): string[] {
    return ['spacing-top-' + this.cueElement.spacing_top, 'spacing-bottom-' + this.cueElement.spacing_bottom];
  }

  public trackByFnc = (i: number, n: CueElement) => n.id;
}
