import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

export interface ISearchResultItem {
  title: string;
  href: string;
  type: string;
  headline: string;
  paragraph: string;
}

export interface ISearchResults {
  results: ISearchResultItem[];
}

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private httpClient: HttpClient) {
  }

  public query(value: string) {
    return this.httpClient.get<ISearchResults>(`${environment.apiUrl}/search/${value}`);
  }
}
