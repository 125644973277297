import { Component, HostBinding, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LanguageService } from '@services/language.service';
import { CueContext } from './cue/cue-core/cue-context';
import { CueContent } from './cue/cue-core/cue-content';
import { MetaService } from '@services/meta.service';
import { filter, skip } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { ViewStateService } from '@core/view-state.service';

@Component({
  selector: 'sdx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  @HostBinding('@.disabled')
  public animationsDisabled = true;

  public isLoading = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private languageService: LanguageService,
    private metaService: MetaService,
    private viewStateService: ViewStateService,
    private router: Router,
    private cueContext: CueContext
  ) { }

  ngOnInit() {
    this.isLoading = true;

    this.viewStateService.listen();

    if (isPlatformBrowser(this.platformId)) {
      if (window.location.host.includes('localhost') || window.location.hostname.includes('illumi.dk')) {
        document.body.classList.add('debug');
      }

      this.router.events.pipe(filter((n) => n instanceof NavigationEnd)).subscribe((event) => {
        (window as any).dataLayer.push({
          event: 'virtualPageView',
          virtualPagePath: (event as NavigationEnd).urlAfterRedirects,
          virtualPageTitle: document.title,
        });
      });
    }

    this.cueContext
      .content<CueContent>()
      .pipe(skip(1))
      .subscribe((content) => {
        this.isLoading = false;

        if (content === null) {
          this.metaService.setFallbackMeta();
          return;
        }

        this.metaService.setMeta(content);

        if (content.statusCode === 404) {
          this.metaService.set404Meta();
        }

        this.languageService.setLanguage(content.metaData?.locale);
      });

    if (isPlatformBrowser(this.platformId)) {
      this.animationsDisabled = false;
    }
  }
}
