import { Component, HostBinding } from '@angular/core';
import { CueContentType, CueElementAware } from '@cue-core';
import { ImageModel } from '@core/content-models';

@CueContentType({ types: ['image'] })
@Component({
  selector: 'sdx-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements CueElementAware {
  public cueElement: ImageModel;

  @HostBinding('class')
  get hostClasses(): string[] {
    return ['spacing-top-' + this.cueElement.spacing_top, 'spacing-bottom-' + this.cueElement.spacing_bottom];
  }

  public inView = false;

}
