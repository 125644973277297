import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, ElementRef, Inject, Input, OnChanges, PLATFORM_ID, SimpleChanges, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sdx-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent implements OnChanges {

  @Input()
  countIf = false;

  @Input()
  text: string;

  template: string;
  digit: number;
  steps = 12;
  duration = 1000;

  @ViewChild('el')
  el: ElementRef;

  counterFunc(endValue: number, durationMs: number, element: ElementRef, template: string) {
    const stepCount = Math.abs(durationMs / this.steps);
    const valueIncrement = (endValue - 0) / stepCount;
    const sinValueIncrement = Math.PI / stepCount;

    let currentValue = 0;
    let currentSinValue = 0;

    function step() {
      currentSinValue += sinValueIncrement;
      currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2;
      const val = Math.abs(Math.floor(currentValue));
      const text = template.replace('[#]', val.toString());

      element.nativeElement.textContent = text;

      if (currentSinValue < Math.PI) {
        window.requestAnimationFrame(step);
      }
    }

    step();
  }

  constructor(@Inject(PLATFORM_ID) private readonly platformId: object) { }


  ngOnChanges(changes: SimpleChanges) {
    if (isPlatformBrowser(this.platformId)) {
      this.parseTextInput();
      if (!changes.firstChange && typeof this.digit === 'number' && this.el && this.countIf) {
        this.counterFunc(this.digit, this.duration, this.el, this.template);
      }
    }
  }

  private parseTextInput() {
    const numbers = this.text.replace(/[^0-9]/g, '');
    this.template = this.text.replace(numbers, '[#]');
    this.digit = parseFloat(numbers);
  }

}
