import { InjectionToken, Type } from '@angular/core';
import { ICueElementMapper } from './cue-element-mapper.interface';
import { ICueAnnotation, ICueBreadCrumbItem, ICueElementDto, ICueMetaData, ICueNavigation, ICuePageDto as ICueContentDto } from './cue-interfaces';

export class CueAnnotations {
  private static getTag(name: string) {
    switch (name) {
      case 'bold':
        return 'b';
      case 'italic':
        return 'i';
      case 'underline':
        return 'u';
      case 'strike':
        return 's';
      case 'subscript':
        return 'sub';
      case 'superscript':
        return 'sup';
      default:
        return null;
    }
  }

  public static parse(value: string, annotations: ICueAnnotation[], mapper: ICueElementMapper) {
    const chars: string[] = value.split('');
    annotations
      .sort((a, b) => b.index - a.index)
      .forEach((a) => {

        if (a.name === 'internal_link' || a.name === 'inline_link') {
          const link = mapper.getLink(a.value);

          const a1 = a.index;
          const a2 = a.index + a.length - 1;
          chars[a1] = `<a href="${link.href}" ${link.newWindow ? 'target="_blank"' : ''} ${link.noFollow ? 'rel="nofollow"' : ''}>${chars[a1]}`;
          chars[a2] = `${chars[a2]}</a>`;
          return;
        }

        const tag = this.getTag(a.name);
        if (tag == null) {
          console.error('Unknown annotation: ' + a.name);
          return;
        }

        const p1 = a.index;
        const p2 = a.index + a.length - 1;
        chars[p1] = `<${tag}>${chars[p1]}`;
        chars[p2] = `${chars[p2]}</${tag}>`;
      });
    return chars.join('');
  }
}

export class CueElement {
  id: string;
  type: string;
  elements: CueElement[];
  level = 0;

  constructor(mapper: ICueElementMapper, element: ICueElementDto = null, parent: CueElement = null) {
    if (element) {
      this.id = element.id;
      this.type = element.type;

      if (parent !== null) {
        this.level = parent.level + 1;
      }

      if (!!element.children) {
        this.elements = element.children.map((id) => mapper.getElement(id, this));
      }

      if (!!element.fields) {
        const data = element.fields.reduce((acc, field) => {
          let value = field.numberValue || field.booleanValue || field.value;
          if (field.annotations && field.annotations.length) {
            value = CueAnnotations.parse(value as string, field.annotations, mapper);
          }
          if (typeof value === 'string') {
            value = value.replace(/\n/g, '<br />');
          }

          acc[field.name] = value;
          return acc;
        }, {} as { [key: string]: number | string | boolean });

        Object.assign(this, data);
      }

      if (!!element.relation && element.type !== 'image' && element.type !== 'logo_item' && element.type !== 'background_image') {
        console.error('Unknown relation', element);
      }
    }
  }
}

export class CueContent {

  title: string;
  type: string;
  metaData: ICueMetaData;
  navigation: ICueNavigation;
  elements: CueElement[];
  breadCrumb?: ICueBreadCrumbItem[];

  constructor(content: ICueContentDto, public statusCode: number) {
    this.title = content.title;
    this.type = content.type;
    this.breadCrumb = content.breadCrumb;

    this.metaData = content.metaData || {
      meta_title: '',
      meta_description: '',
      meta_robots: '',
      locale: '',
      type: '',
      canonical_url: '',
      meta_author: '',
      og_image: null
    };

    this.navigation = content.navigation;

    if (!this.metaData.locale) {
      this.metaData.locale = 'en_GB';
    }
  }
}

export interface CueElementAware {
  cueElement: CueElement;
}

export interface CueContentAware {
  cueContent: CueContent;
}

export const CURRENT_CUE_CONTENT = new InjectionToken<Type<any>[][]>('CURRENT_CUE_CONTENT');

export const CUE_HEADLESS_HOST = new InjectionToken<Type<any>[][]>('CUE_HEADLESS_HOST');
