import { OnDestroy, Component, HostBinding } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({ template: '' })
// tslint:disable-next-line: directive-class-suffix
export abstract class BaseComponent implements OnDestroy {

    protected destroyed$ = new Subject<boolean>();

    @HostBinding('@.disabled')
    public disableAnimations = true;

    public ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    protected takeUntilDestroyed<T>()  {
        return takeUntil<T>(this.destroyed$);
    }
}
