<div class="container-01" (sdxInView)="cameInView($event)">
  <div class="player-outer">

    <div class="player-wrapper">
      <picture class="player-cover-image lazyload-picture ratio-16-9" *ngIf="!isPlaying && cueElement.imageSources && !cueElement.is_silent" [ngClass]="{'is-ready': cueElement.image.isLoaded}">
        <button type="button" class="player-button" aria-label="Play" (click)="play()"><span class="sr-only">Play</span></button>
        <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" class="lazyload-img" [alt]="cueElement.image.alt_text" [sdxLazyload]="cueElement.imageSources" [lazyloadIf]="inView" (lazyloaded)="cueElement.image.isLoaded = true;" />
      </picture>

      <div class="player-container" #playerContainer [ngClass]="{'is-ready': isReady}">
        <div class="player-consent-text" *ngIf="!hasConcent" (click)="openCookieDialog()">
          <p>You have to consent to functional cookies to see this content on Vimeo. Click here to renew consent.</p>
        </div>
      </div>
    </div>
  </div>

</div>
