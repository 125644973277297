import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ReplaySubject } from 'rxjs';

export enum Breakpoint {
  XS_MIN = `(min-width: 0)`,
  XS = `(min-width: 0) and (max-width: 575.99px)`,
  XS_MAX = `(max-width: 575.99px)`,

  SM_MIN = `(min-width: 576px)`,
  SM = `(min-width: 576px) and (max-width: 767.99px)`,
  SM_MAX = `(max-width: 767.99px)`,

  MD_MIN = `(min-width: 768px)`,
  MD = `(min-width: 768px) and (max-width: 991.99px)`,
  MD_MAX = `(max-width: 991.99px)`,

  LG_MIN = `(min-width: 992px)`,
  LG = `(min-width: 992px) and (max-width: 1439.99px)`,
  LG_MAX = `(max-width: 1439.99px)`,

  XL_MIN = `(min-width: 1440px)`,
  XL = `(min-width: 1440px)`,
  // XL_MAX = '(max-width: 1699.99px)',
}

@Injectable({
  providedIn: 'root'
})
export class BreakpointService {

  public active = new ReplaySubject<Breakpoint[]>();

  constructor(public breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([
        Breakpoint.XS_MIN,
        Breakpoint.XS,
        Breakpoint.XS_MAX,
        Breakpoint.SM_MIN,
        Breakpoint.SM,
        Breakpoint.SM_MAX,
        Breakpoint.MD_MIN,
        Breakpoint.MD,
        Breakpoint.MD_MAX,
        Breakpoint.LG_MIN,
        Breakpoint.LG,
        Breakpoint.LG_MAX,
        Breakpoint.XL_MIN,
        Breakpoint.XL
      ])
      .subscribe(res => {
        this.active.next(Object.keys(res.breakpoints).filter(k => res.breakpoints[k]) as Breakpoint[]);
      });
  }
}
