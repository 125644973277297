import { Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderComponent } from './slider/slider.component';
import { SlideComponent } from './slide/slide.component';
import { HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CTAsModule } from '@ctas/ctas.module';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  public overrides = {
    swipe: { velocity: 2, direction: 6 },
  };
}

@NgModule({
  declarations: [SliderComponent, SlideComponent],
  imports: [CommonModule, BrowserAnimationsModule, HammerModule, CTAsModule],
  providers: [
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
  ],
  exports: [SliderComponent, SlideComponent],
})
export class SliderModule { }
