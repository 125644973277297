<div class="search-results">
  <div class="search-result-count">Showing {{results.results.length}} results</div>

  <div class="search-result-content" *ngFor="let result of results.results">

    <ol class="search-breadcrumbs-list" *ngIf="isArray(result.breadCrumb)">
      <li class="search-breadcrumbs-item" *ngFor="let breadcrumb of result.breadCrumb; let i = index">
        <a class="search-breadcrumbs-action" [attr.href]="breadcrumb.url"><span>{{breadcrumb.title}}</span></a>
      </li>
    </ol>

    <h4 class="search-result-title"><a [routerLink]="[result.href]">
        {{!!result.title ? result.title : result.headline}}</a></h4>
    <p class="search-result-body" *ngIf="!!result.paragraph">{{result.paragraph}}</p>
  </div>

  <div class="search-result-content" *ngIf="results.results.length === 0">
    <h4 class="search-result-title">Your search – <strong>{{query}}</strong> – did not return any results</h4>
    <ul>
      <li> Make sure the keywords are spelled correctly</li>
      <li>Try writing the whole keyword</li>
      <li>Try entering fewer keywords</li>
      <li>Try another keyword</li>
    </ul>
  </div>

</div>
