import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FeatureCardModel } from '@cue-models';

@Component({
  selector: 'sdx-feature-card',
  templateUrl: './feature-card.component.html',
  styleUrls: ['./feature-card.component.scss']
})
export class FeatureCardComponent {

  @Input()
  theme: string;

  @Input()
  ctaTheme: string;

  @Input()
  card: FeatureCardModel;

  @Input()
  imagePadding = false;

  public isHovered = false;
  public inView = false;

}
