import { Injectable } from '@angular/core';
import { CueContent } from './cue-content';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CueContext {
  constructor(private contentAccessor: CueContentAccessor) {}

  public content<T extends CueContent>(): Observable<T> {
    return this.contentAccessor.current$.asObservable() as Observable<T>;
  }

  public contentSnapshot<T extends CueContent>(): T {
    return this.contentAccessor.current$.getValue() as T;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CueContentAccessor {
  public current$: BehaviorSubject<CueContent> = new BehaviorSubject<CueContent>(null);
}
