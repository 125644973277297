import { HostBinding } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { CueContentType, CueElementAware } from '@cue-core';
import { AnimationStateService } from '@services/animation-state.service';
import { staggerIn } from '@core/animations';
import { BaseComponent } from '@core/base.component';
import { HeadlineMediumLargeModel } from '@core/content-models';

@CueContentType({ types: ['headline_medium_large'] })
@Component({
  selector: 'sdx-headline-medium-large',
  template: `<div (sdxInView)="inView = true" [@staggerIn]="inView"><h2 class="stagger-in" [innerHTML]="cueElement.headline"></h2></div>  `,
  animations: [staggerIn],
  styles: [`
      :host { display: block;}
      :host.is-centered * { text-align: center; }
    `,
  ],
})
export class HeadlineMediumLargeComponent extends BaseComponent implements OnInit, CueElementAware {

  public cueElement: HeadlineMediumLargeModel;

  @HostBinding('class')
  get hostClasses(): string[] {

    const classList = [
      'spacing-top-' + this.cueElement.spacing_top,
      'spacing-bottom-' + this.cueElement.spacing_bottom,
    ];

    if (this.cueElement.level === 0) {
      classList.push('p-horizontal');
    }

    if (this.cueElement.isCentered) {
      classList.push('is-centered');
    }

    return classList;
  }

  public inView = false;

  constructor(private animationStateService: AnimationStateService) {
    super();
  }

  ngOnInit() {
    this.animationStateService.disabled
      .pipe(this.takeUntilDestroyed())
      .subscribe((disabled) => this.disableAnimations = disabled);
  }

}
