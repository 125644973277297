import { Directive, Input, ElementRef, OnChanges } from '@angular/core';

@Directive({
  selector: '[sdxFocusOn]'
})
export class FocusOnDirective implements OnChanges {

  @Input('sdxFocusOn')
  private focus = false;

  constructor(public element: ElementRef<HTMLElement>) {}

  ngOnChanges(): void {
    if (this.focus) {
      setTimeout(() => this.element.nativeElement.focus(), 50);
    }
  }
}
